import { SVGProps } from 'react';

const InputIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 1.1C0 1.70867 0.478571 2.2 1.07143 2.2H4.92857V11.9C4.92857 12.5087 5.40714 13 6 13C6.59286 13 7.07143 12.5087 7.07143 11.9V2.2H10.9286C11.5214 2.2 12 1.70867 12 1.1C12 0.491333 11.5214 0 10.9286 0H1.07143C0.478571 0 0 0.491333 0 1.1Z"
        fill="#888CAB"
      />
    </svg>
  );
};

export default InputIcon;
