export enum LoadingStatus {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export interface RequestErrors {
  message: string;
  statusCode: number;
  error: string;
}

export interface ResponseUnionType<T> {
  statusCode: number;
  message: string;
  data: T;
}

export type BookmarksListPlaybackMarkersType = Array<{ id: number; start: number; end: number }>;
