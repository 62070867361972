import axios from 'axios';
import { phonexiaServerString, phonexiaSessionId } from 'config';
import { selectVerificationSpeaker } from 'features/speakers/selectors';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notifyErr } from 'utils/notification';

interface Props {
  onGetScore: (startDate: Date, score?: number) => void;
  setPhonexiaIsAvailable: (value: boolean) => void;
}

export default function usePhonexiaVoiceprint({ onGetScore, setPhonexiaIsAvailable }: Props) {
  const verificationSpeaker = useSelector(selectVerificationSpeaker) || localStorage.getItem('verification-speaker');
  const { t } = useTranslation('sid');
  const [modelVoiceprint, setModelVoiceprint] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [source, setSource] = useState<MediaStreamAudioSourceNode | null>(null);
  const [processor, setProcessor] = useState<ScriptProcessorNode | null>(null);
  const [inputStreamData, _setInputStreamData] = useState<{ port: number; input_stream: string }>();
  const [inputStreamTaskInfo, _setInputStreamTaskInfo] = useState<{
    id: string;
    input_stream_id: string;
    state: string;
  }>();

  const inputStreamDataRef = useRef(inputStreamData);
  const setInputStreamData = (data?: { port: number; input_stream: string }) => {
    inputStreamDataRef.current = data;
    _setInputStreamData(data);
  };

  const inputStreamTaskInfoRef = useRef(inputStreamTaskInfo);
  const setInputStreamTaskInfo = (data?: { id: string; input_stream_id: string; state: string }) => {
    inputStreamTaskInfoRef.current = data;
    _setInputStreamTaskInfo(data);
  };

  useEffect(() => {
    if (verificationSpeaker) {
      axios
        .get(
          phonexiaServerString + `/technologies/speakerid4/speakermodels/${verificationSpeaker}/voiceprint?model=XL5`,
          {
            headers: { 'X-SessionID': phonexiaSessionId! },
          },
        )
        .then((data) => {
          setModelVoiceprint(data.data.result.voiceprint);
        });
    }
  }, [verificationSpeaker]);

  useEffect(() => {
    const abortController = new AbortController();

    const audioContext = new window.AudioContext();

    if (verificationSpeaker) {
      axios
        .post(
          phonexiaServerString + `/input_stream/http?frequency=${audioContext.sampleRate}`,
          {},
          { headers: { 'X-SessionID': phonexiaSessionId! }, signal: abortController.signal },
        )
        .then((data) => {
          setInputStreamData(data.data.result);
          axios
            .post(
              phonexiaServerString +
                `/technologies/speakerid4/input_stream/voiceprint?input_stream=${data.data.result.input_stream}&model=XL5`,
              null,
              { headers: { 'X-SessionID': phonexiaSessionId! }, signal: abortController.signal },
            )
            .then((data) => {
              setPhonexiaIsAvailable(true);
              setInputStreamTaskInfo(data.data.result.stream_task_info);
            });
        })
        .catch(() => {
          setPhonexiaIsAvailable(false);
          notifyErr(t('errorMessageVerifySpeaker'), '12');
        });
    }

    return () => {
      abortController.abort();
    };
  }, [verificationSpeaker]);

  const handleStartRecording = async () => {
    if (!isRecording) {
      const startDate = new Date();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      const newAudioContext = new window.AudioContext();
      const volume = newAudioContext.createGain();
      const newSource = newAudioContext.createMediaStreamSource(stream);

      const bufferSize = 16384;

      newSource.connect(volume);

      const newProcessor = newAudioContext.createScriptProcessor.call(newAudioContext, bufferSize, 1, 1);
      volume.connect(newProcessor);
      newProcessor.connect(newAudioContext.destination);

      newProcessor.onaudioprocess = (e) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const samples: any = e.inputBuffer.getChannelData(0);
        const buffer = new ArrayBuffer(bufferSize * 2);
        const PCM16iSamples = new Int16Array(buffer);
        let offset = 0;
        for (const sample of samples) {
          let val = Math.floor(32767 * sample);
          val = Math.min(32767, val);
          val = Math.max(-32768, val);
          PCM16iSamples[offset++] = val;
        }

        if (inputStreamData?.input_stream && inputStreamTaskInfo?.id && modelVoiceprint) {
          axios
            .put(
              phonexiaServerString + `/input_stream/http?input_stream=${inputStreamData.input_stream}`,
              PCM16iSamples,
              {
                headers: {
                  'X-SessionID': phonexiaSessionId!,
                },
              },
            )
            .then(() => {
              axios
                .get(
                  phonexiaServerString +
                    `/technologies/speakerid4/input_stream/voiceprint?task=${inputStreamTaskInfo.id}`,
                  {
                    headers: { 'X-SessionID': phonexiaSessionId! },
                  },
                )
                .then((data) => {
                  if (data.data.result.voiceprint) {
                    axios
                      .post(
                        phonexiaServerString + '/technologies/speakerid4/comparevp?model=XL5',
                        { voiceprints: [modelVoiceprint, data.data.result.voiceprint] },
                        {
                          headers: { 'X-SessionID': phonexiaSessionId! },
                        },
                      )
                      .then((data) => {
                        axios
                          .get(phonexiaServerString + `/done/${data.data.result.info.id}`, {
                            headers: { 'X-SessionID': phonexiaSessionId! },
                          })
                          .then((data) => {
                            const score = data.data.result.results[0].channel_scores[0].scores[0].score;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            const finalScore = (Math.exp(score) / (1 + Math.exp(score))).toFixed(4) * 100;
                            onGetScore(startDate, finalScore);
                          });
                      });
                  } else {
                    onGetScore(startDate);
                  }
                })
                .catch(() => {
                  window.location.reload();
                  //(AuthorizedRoutes.REAL_SID);
                  notifyErr(t('errorMessageVerifySpeaker'), '13');
                });
            })
            .catch(() => {
              window.location.reload();
              //navigate(AuthorizedRoutes.REAL_SID);
              notifyErr(t('errorMessageVerifySpeaker'), '14');
            });
        }
      };

      setAudioContext(newAudioContext);
      setSource(newSource);
      setProcessor(newProcessor);
      setIsRecording(true);
    }
  };

  const handleStopRecording = async () => {
    if (inputStreamDataRef.current) {
      try {
        await axios.delete(
          phonexiaServerString + `/input_stream/http?input_stream=${inputStreamDataRef.current?.input_stream}`,
          {
            headers: { 'X-SessionID': phonexiaSessionId! },
          },
        );
      } catch (error) {
        window.location.reload();
        notifyErr(t('errorMessageVerifySpeaker'), '15');
      }

      try {
        await axios.delete(
          phonexiaServerString + `/technologies/speakerid4/input_stream?task=${inputStreamTaskInfoRef.current?.id}`,
          {
            headers: { 'X-SessionID': phonexiaSessionId! },
          },
        );
      } catch (error) {
        window.location.reload();
        notifyErr(t('errorMessageVerifySpeaker'), '16');
      }
    }

    setInputStreamData();
    setInputStreamTaskInfo();

    if (source && audioContext && processor) {
      source.disconnect();
      processor.disconnect();
      audioContext.close();
      setIsRecording(false);
    }
  };

  return {
    handleStartRecording,
    handleStopRecording,
    isCanStart: !!inputStreamTaskInfo,
    inputStreamData,
    inputStreamId: inputStreamTaskInfo?.id,
    modelVoiceprint,
  };
}
