import backgroundImage from 'assets/images/auth-bg-img.jpg';
import logoImageDesktop from 'assets/images/logoDesktop.png';
import logoImageMobile from 'assets/images/logoMobile.png';
import { gaMeasurementId } from 'config';
import { corporateWebsiteLink, UnauthorizedRoutes } from 'constants/routes';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ReactGA from 'react-ga4';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { LoginForm, RegistrationForm } from './components';
import { RequestVerificationLinkForm } from './components/RequestVerificationLinkForm';
import { Verification } from './components/Verification';

ReactGA.initialize(gaMeasurementId);

const AuthorizationPage = () => {
  const location = useLocation();
  const { isMobil } = useWindowDimensions();
  ReactGA.send({ hitType: 'pageview', page: '/auth/sign-in', title: 'Demo Login' });

  return (
    <Root>
      <FormWrapper>
        <Logo to={corporateWebsiteLink}>
          <img src={isMobil ? logoImageMobile : logoImageDesktop} width={isMobil ? '140' : '240'} />
        </Logo>
        {location.pathname === UnauthorizedRoutes.SIGN_IN && <LoginForm />}
        {location.pathname === UnauthorizedRoutes.SIGN_UP && <RegistrationForm />}
        {location.pathname === UnauthorizedRoutes.VERIFICATION && <Verification />}
        {location.pathname === UnauthorizedRoutes.REQUEST_VERIFICATION_LINK && <RequestVerificationLinkForm />}
      </FormWrapper>
      <BackgroundImage />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  @media (max-width: 765px) {
    flex-direction: column-reverse;
    overflow: auto;
    height: 100vh;
  }
`;

const FormWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  height: 100%;
  padding: 64px 5% 74px 10%;
  border-radius: 0 40px 40px 0;
  background: ${({ theme: { colors } }) => colors.white};

  @media (max-width: 765px) {
    position: static;
    width: 100%;
    margin-top: -100px;
    border-radius: 40px 40px 0 0;
    padding: 24px 30px;
    overflow: visible;
    z-index: 10;
  }
`;

const BackgroundImage = styled.div`
  flex: 1 1 auto;
  margin-left: 35%;
  background: url(${backgroundImage}) 50% / cover no-repeat;

  @media (max-width: 765px) {
    margin: 0;
    height: 30%;
    min-height: 300px;
    max-height: 30%;
    width: 100%;
    background: url(${backgroundImage}) 0 0 / 100% auto no-repeat;
  }
`;

const Logo = styled(Link)`
  margin-bottom: 20%;
`;

export default AuthorizationPage;
