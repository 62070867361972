import { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';

import { defaultTheme } from './index';

const Theme: FC<PropsWithChildren> = ({ children }) => {
  return <ThemeProvider theme={defaultTheme}> {children}</ThemeProvider>;
};

export default Theme;
