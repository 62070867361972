// import axios from 'axios';
// import { phonexiaServerString, phonexiaSessionId } from 'config';
// import { AnalyzeApi } from 'api/analyze.api';
import { useEffect, useRef } from 'react';

import useMediaRecorder from './useMediaRecorder';

export default function useMediaStream() {
  const mediaRecorder = useRef<MediaRecorder>();

  const {
    mediaStream,
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
  } = useMediaRecorder();

  const stopMediaRecording = () => {
    mediaRecorder.current?.stop();
  };

  useEffect(() => {
    if (mediaStream && mediaStream.active) {
      mediaRecorder.current = new MediaRecorder(mediaStream);
      mediaRecorder.current.start(1000);
    }
  }, [mediaStream]);

  return {
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    stopMediaRecording,
    status,
  };
}
