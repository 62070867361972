import emailjs from '@emailjs/browser';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from 'components/atoms';
import { EmailIcon, LockIcon, UserIcon } from 'components/atoms/svg';
import { InputField } from 'components/molecules';
import { emailJSPublicKey, emailJSServiceId, emailJSTemplateId, verificationTokenURL } from 'config';
import { UnauthorizedRoutes } from 'constants/routes';
import { selectLoadingStatus } from 'features/auth/selectors';
import { signUpAsync } from 'features/auth/thunks';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';
import { notifyErr } from 'utils/notification';
import { patterns } from 'utils/patterns';

interface FormValue {
  email: string;
  emailConfirm: string;
  name: string;
  password: string;
  passwordConfirm: string;
}

const RegistrationForm = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const [stage, setStage] = useState<'userData' | 'password'>('userData');
  const [isDataSent, setIsDataSent] = useState(false);
  const [emailIsSending, setEmailIsSending] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValue>({
    defaultValues: { name: '', email: '', emailConfirm: '', password: '', passwordConfirm: '' },
    mode: 'onChange',
  });

  const { isMobil } = useWindowDimensions();

  const fieldNameValue = watch('name');
  const fieldEmailValue = watch('email');

  const onSubmit = async (data: FormValue) => {
    setEmailIsSending(true);
    await dispatch(signUpAsync({ name: data.name, email: data.email, password: data.password }))
      .then(async (response) => {
        unwrapResult(response);
        setEmailIsSending(true);
        await emailjs.send(
          emailJSServiceId,
          emailJSTemplateId,
          {
            link: `${verificationTokenURL}/auth/verification?token=${response.payload.data.token}`,
            recipientEmail: fieldEmailValue,
            linkName: verificationTokenURL,
          },
          emailJSPublicKey,
        );
      })
      .then(() => {
        setEmailIsSending(false);
        setIsDataSent(true);
      })
      .catch((error) => {
        setEmailIsSending(false);
        if (error?.statusCode && error.statusCode === 409) {
          notifyErr(t('errorMessageRegistrationExistEmail'), '3');
          setStage('userData');
        } else {
          notifyErr(t('errorMessageRegistration'), '4');
          setStage('userData');
        }
      });
  };

  const stepToNextStage = () => {
    setStage('password');
  };

  if (isDataSent) {
    return (
      <Root>
        <LargeText>{t('signUpThanks')}</LargeText>
        <LinkWrapper>
          <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
        </LinkWrapper>
      </Root>
    );
  }

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Title>{stage === 'userData' ? t('createAnAccount') : t('password')}</Title>

      {!isMobil ? (
        <>
          <FieldWrapper>
            <InputField
              rules={{ required: { value: true, message: t('requiredField') } }}
              control={control}
              name="name"
              label={t('name')}
              icon={<LockIcon />}
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              control={control}
              name="email"
              rules={{
                required: { value: true, message: t('requiredField') },
                pattern: {
                  value: patterns.email,
                  message: t('invalidEmailAddressFormat'),
                },
              }}
              label={t('email')}
              icon={<EmailIcon />}
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              control={control}
              name="emailConfirm"
              rules={{
                required: { value: true, message: t('requiredField') },
                pattern: {
                  value: patterns.email,
                  message: t('invalidEmailAddressFormat'),
                },
                validate: (value: string, formValues: FormValue) => {
                  return value === formValues.email || t('emailsMustMatch')!;
                },
              }}
              label={t('confirmEmail')}
              icon={<EmailIcon />}
            />
          </FieldWrapper>
          <FieldWrapper>
            <InputField
              type="password"
              rules={{ required: { value: true, message: t('requiredField') } }}
              control={control}
              name="password"
              label={t('password')}
              icon={<LockIcon />}
            />
          </FieldWrapper>
          <FieldWrapper $mb={24}>
            <InputField
              type="password"
              rules={{
                required: { value: true, message: t('requiredField') },
                validate: (value: string, formValues: FormValue) => {
                  return value === formValues.password || t('passwordsMustMatch')!;
                },
              }}
              control={control}
              name="passwordConfirm"
              label={t('confirmPassword')}
              icon={<LockIcon />}
            />
          </FieldWrapper>
          <StyledButton text={t('signUp').toUpperCase()} disabled={isLoading || emailIsSending} />
        </>
      ) : (
        <>
          {stage === 'userData' ? (
            <>
              <FieldWrapper>
                <InputField
                  rules={{ required: { value: true, message: t('requiredField') } }}
                  control={control}
                  name="name"
                  label={t('name')}
                  icon={<LockIcon />}
                />
              </FieldWrapper>
              <FieldWrapper>
                <InputField
                  control={control}
                  name="email"
                  rules={{
                    required: { value: true, message: t('requiredField') },
                    pattern: {
                      value: patterns.email,
                      message: t('invalidEmailAddressFormat'),
                    },
                  }}
                  label={t('email')}
                  icon={<UserIcon />}
                />
              </FieldWrapper>
              <FieldWrapper $mb={24}>
                <InputField
                  control={control}
                  name="emailConfirm"
                  rules={{
                    required: { value: true, message: t('requiredField') },
                    pattern: {
                      value: patterns.email,
                      message: t('invalidEmailAddressFormat'),
                    },
                    validate: (value: string, formValues: FormValue) => {
                      return value === formValues.email || t('emailsMustMatch')!;
                    },
                  }}
                  label={t('confirmEmail')}
                  icon={<EmailIcon />}
                />
              </FieldWrapper>
              <StyledButton
                text={t('next')}
                type="button"
                disabled={!!errors.name || !!errors.email || !fieldNameValue.length || !fieldEmailValue.length}
                onClick={stepToNextStage}
              />
            </>
          ) : (
            <>
              <div></div>
              <div></div>
              <div></div>
              <FieldWrapper>
                <InputField
                  type="password"
                  rules={{ required: { value: true, message: t('requiredField') } }}
                  control={control}
                  name="password"
                  label={t('password')}
                  icon={<LockIcon />}
                />
              </FieldWrapper>
              <FieldWrapper $mb={24}>
                <InputField
                  type="password"
                  rules={{
                    required: { value: true, message: t('requiredField') },
                    validate: (value: string, formValues: FormValue) => {
                      return value === formValues.password || t('passwordsMustMatch')!;
                    },
                  }}
                  control={control}
                  name="passwordConfirm"
                  label={t('Confirm password')}
                  icon={<LockIcon />}
                />
              </FieldWrapper>
              <StyledButton
                text={t('signUp').toUpperCase()}
                disabled={isLoading || !!errors.passwordConfirm || emailIsSending}
              />
            </>
          )}
        </>
      )}

      <LinkWrapper>
        <Text>{t('accountExistQuestion')}</Text>
        <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
      </LinkWrapper>
      <RightsText>© 2024 DARWIN INTELLIGENCE. All rights reserved</RightsText>
    </Root>
  );
};

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 765px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.h1}
`;

const FieldWrapper = styled.div<{ $mb?: number }>`
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : '16px')};
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
`;

const Text = styled.span`
  ${({ theme }) => theme.typography.text};
  margin-right: 8px;
`;

const LargeText = styled.h2`
  ${({ theme: { typography } }) => typography.largeText}
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 765px) {
    margin-bottom: 52px;
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const RightsText = styled.div`
  ${({ theme }) => theme.typography.smallText};
  margin-top: auto;

  @media (max-width: 765px) {
    text-align: center;
  }
`;

export default RegistrationForm;
