import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={20} fill="#888CAB" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.625 11.875h-3.75A6.86 6.86 0 0 0 0 18.75C0 19.453.547 20 1.25 20h15c.664 0 1.25-.547 1.25-1.25 0-3.79-3.086-6.875-6.875-6.875Zm-8.75 6.25a5.027 5.027 0 0 1 5-4.375h3.75c2.54 0 4.648 1.914 4.96 4.375H1.876ZM8.75 10c2.734 0 5-2.227 5-5 0-2.734-2.266-5-5-5-2.773 0-5 2.266-5 5 0 2.773 2.227 5 5 5Zm0-8.125A3.134 3.134 0 0 1 11.875 5 3.11 3.11 0 0 1 8.75 8.125C6.992 8.125 5.625 6.758 5.625 5A3.11 3.11 0 0 1 8.75 1.875Z" />
  </svg>
);

export default SvgComponent;
