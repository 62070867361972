import 'react-toastify/dist/ReactToastify.css';

import useSetMobileViewport from 'hooks/useSetMobileViewport';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Theme from 'styles/themes';

import { AppRouting } from './routes';
import GlobalStyles from './styles/GlobalStyles';

function App() {
  useSetMobileViewport();

  return (
    <Theme>
      <GlobalStyles />
      <ToastContainer />
      <AppRouting />
    </Theme>
  );
}

export default App;
