import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={8} fill="#424565" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M7.125 7.625a.866.866 0 0 1-.629-.246l-5.25-5.25a.843.843 0 0 1 0-1.23.843.843 0 0 1 1.23 0l4.649 4.62L11.746.9a.843.843 0 0 1 1.23 0 .843.843 0 0 1 0 1.23l-5.25 5.25a.848.848 0 0 1-.601.246Z" />
  </svg>
);

export default SvgComponent;
