import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { ChatStore } from './types';

export const selectChats = (state: RootState): ChatStore => state.chat;
export const selectUploadResult = createSelector(selectChats, (chat) => chat.uploadResult);
export const selectUploadFiles = createSelector(selectChats, (chat) => chat.uploadFiles);
export const selectKnowledge = createSelector(selectChats, (chat) => chat.knowledge);
export const selectRAG = createSelector(selectChats, (chat) => chat.rag);
export const selectLoadingStatus = createSelector(selectChats, (chat) => chat.loadingStatus);
