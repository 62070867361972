import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={10} height={12} fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 1.766A1 1 0 0 1 1.514.91l7.057 4.234a1 1 0 0 1 0 1.714l-7.056 4.234A1 1 0 0 1 0 10.234V1.766Z" />
  </svg>
);

export default SvgComponent;
