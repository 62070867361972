import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={10} height={11} fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y={0.333} width={10} height={10} rx={2} />
  </svg>
);

export default SvgComponent;
