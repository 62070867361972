import { FC, memo } from 'react';
import styled from 'styled-components';

interface ChartLabelProps {
  text: string;
  isHighlight: boolean;
  highlightColor: string;
}

const ChartLabel: FC<ChartLabelProps> = ({ text, isHighlight, highlightColor }) => {
  return (
    <Root $isHighlight={isHighlight} $highlightColor={highlightColor}>
      {text}
    </Root>
  );
};

const Root = styled.div<{ $isHighlight: boolean; $highlightColor: string }>`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: ${({ $isHighlight }) => ($isHighlight ? '24px' : '16px')};
  line-height: 140%;
  color: ${({ $isHighlight, $highlightColor, theme: { colors } }) =>
    $isHighlight ? $highlightColor : colors.disabled};

  @media (max-width: 765px) {
    font-size: ${({ $isHighlight }) => ($isHighlight ? '18px' : '14px')};
  }
`;

export default memo(ChartLabel);
