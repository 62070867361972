import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 2.667V8M16 24v5.333M6.573 6.573l3.774 3.774M21.653 21.653l3.774 3.774M2.667 16H8M24 16h5.333M6.573 25.427l3.774-3.774M21.653 10.347l3.774-3.774"
      stroke="#5868F5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
