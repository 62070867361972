import { RealtimeSttType } from 'features/analyze/types';

export default function groupBySpeaker(words: RealtimeSttType) {
  let currentSpeaker = words[0].speaker;

  const speakerResults: { speaker: number; words: string[] }[] = [];
  let currentSpeakerIndex = 0;

  words.map((res) => {
    if (currentSpeaker === res.speaker) {
      if (!speakerResults[currentSpeakerIndex]) {
        speakerResults.push({
          speaker: res.speaker,
          words: [res.word],
        });
      } else {
        speakerResults[currentSpeakerIndex] = {
          ...speakerResults[currentSpeakerIndex],
          words: [...speakerResults[currentSpeakerIndex].words, res.word],
        };
      }
    } else {
      currentSpeaker = res.speaker;
      currentSpeakerIndex += 1;
      speakerResults.push({
        speaker: res.speaker,
        words: [res.word],
      });
    }
  });

  return speakerResults;
}
