import { FC, InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: ReactNode;
  success?: boolean;
  hasError?: boolean;
  label: string;
}

const Input: FC<InputProps> = ({ name, hasError = false, icon, label, ...props }) => {
  return (
    <Root>
      {icon && <Icon $hasError={hasError}>{icon}</Icon>}
      <StyledInput id={name} $hasError={hasError} $hasIcon={!!icon} {...props} />
      <Label htmlFor={name} $isInputHasValue={!!props.value} $hasIcon={!!icon}>
        {label}
      </Label>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Icon = styled.div<{ $hasError: boolean }>`
  position: absolute;
  display: flex;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  width: 52px;

  & svg {
    ${({ $hasError, theme }) =>
      $hasError
        ? css`
            fill: ${theme.colors.red};
          `
        : ''}
  }
`;

const labelTopPosition = css`
  text-align: center;
  display: block;
  left: 15px;
  top: -8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme: { colors } }) => colors.purple};
  background: ${({ theme: { colors } }) => colors.white};
  height: 17px;
  padding: 0 4px;
  transform: translateY(0);
`;

const Label = styled.label<{ $isInputHasValue: boolean; $hasIcon: boolean }>`
  position: absolute;
  top: 50%;
  left: ${({ $hasIcon }) => ($hasIcon ? '52px' : '16px')};
  z-index: 10;
  transform: translateY(-50%);
  transition: linear 0.1s;
  ${({ theme: { typography } }) => typography.largeText}

  ${({ $isInputHasValue }) => ($isInputHasValue ? labelTopPosition : '')};

  @media (max-width: 765px) {
    font-size: 14px;
  }
`;

const StyledInput = styled.input<{ $hasError: boolean; $hasIcon: boolean }>`
  width: 100%;
  ${({ theme: { typography } }) => typography.largeText}
  background: ${({ $hasError, theme: { colors } }) => ($hasError ? colors.lightRed : 'transparent')};
  border: 2px solid ${({ $hasError, theme: { colors } }) => ($hasError ? colors.red : colors.lightPurple)};
  border-radius: 6px;
  padding: 14px 16px 14px ${({ $hasIcon }) => ($hasIcon ? '52px' : '16px')};

  &:hover {
    border: 2px solid ${({ $hasError, theme: { colors } }) => ($hasError ? colors.red : colors.border)};
  }

  &:focus {
    border: 2px solid ${({ $hasError, theme: { colors } }) => ($hasError ? colors.red : colors.purple)};
  }

  &:focus ~ ${Label} {
    ${labelTopPosition}
  }
`;

export default Input;
