import { SVGProps } from 'react';

const IconRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 1.66666V6.66666H7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 10C17.4987 8.55277 17.0787 7.13682 16.2906 5.92295C15.5026 4.70909 14.3802 3.74914 13.0587 3.15891C11.7373 2.56868 10.2734 2.37338 8.84345 2.59655C7.41352 2.81973 6.07871 3.45185 5 4.41667L2.5 6.66667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 18.3333V13.3333H12.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 10C2.50132 11.4472 2.92133 12.8632 3.70938 14.0771C4.49743 15.2909 5.61985 16.2509 6.94126 16.8411C8.26267 17.4313 9.72662 17.6266 11.1566 17.4035C12.5865 17.1803 13.9213 16.5482 15 15.5833L17.5 13.3333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconRefresh;
