import { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

const CreatePortal = (props: PropsWithChildren) => {
  const { children } = props;
  const modalRoot = document.getElementById('modal-root') as HTMLDivElement;
  const { createPortal } = ReactDOM;
  return createPortal(children, modalRoot);
};

export default CreatePortal;
