import { AxiosResponse } from 'axios';
import { authHttp } from 'services/http';
import { ResponseUnionType } from 'types';

export interface SignInDto {
  email: string;
  password: string;
}

export enum Role {
  ADMIN = 'admin',
  USER = 'user',
}

export interface SignUpDto {
  name: string;
  email: string;
  password: string;
}
export type SignUpResDto = SignInResDto;

export interface SignInResDto {
  data: {
    userId: string;
    email: string;
    name: string;
    role: Role;
    accessToken: string;
    expiresIn: string;
  };
  message: string;
  statusCode: number;
}

export interface CheckAuthResDto {
  userId: string;
  email: string;
  name: string;
  role: Role;
}

export const AuthApi = {
  signIn: async (data: SignInDto): Promise<AxiosResponse<SignInResDto>> => {
    return authHttp.post('auth/login', data);
  },
  signUp: async (data: SignUpDto): Promise<AxiosResponse<SignUpResDto>> => {
    return authHttp.post('auth/register', data);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verifyToken: async (data: string): Promise<AxiosResponse<ResponseUnionType<any>>> => {
    return authHttp.post('auth/verify-token', { token: data });
  },
  requestVerifyToken: async (data: string): Promise<AxiosResponse<ResponseUnionType<string>>> => {
    return authHttp.post('auth/verification-token', { email: data });
  },
};
