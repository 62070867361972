import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../types';
import { getDiarizedFileAsync, getTranslationAsync } from './thunks';
import { AnalyzeStore, RealtimeSttType } from './types';

const initialState: AnalyzeStore = {
  realtimeStt: [],
  diarizedResult: undefined,
  currentAudioFilename: '',
  getDiarizedLoadingStatus: LoadingStatus.idle,
  getTranslationLoadingStatus: LoadingStatus.idle,
  translation: undefined,
  error: null,
};

const analyzeSlice = createSlice({
  name: 'analyze',
  initialState,
  reducers: {
    setRealtimeStt: (state, { payload }: PayloadAction<RealtimeSttType>) => {
      return {
        ...state,
        realtimeStt: state.realtimeStt.concat(payload),
      };
    },
    clearRealtimeStt: (state) => {
      return {
        ...state,
        realtimeStt: [],
      };
    },
    clearDiarizedResult: (state) => {
      return {
        ...state,
        diarizedResult: undefined,
      };
    },
    setCurrentAudioFilename: (state, { payload }: PayloadAction<string>) => {
      return { ...state, currentAudioFilename: payload };
    },
    updateDiarizationOfFile: (state, action) => {
      return {
        ...state,
        diarizedResult: { ...state.diarizedResult!, diarization: action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiarizedFileAsync.pending, (state) => {
      if (state.getDiarizedLoadingStatus === LoadingStatus.idle) {
        state.getDiarizedLoadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getDiarizedFileAsync.fulfilled, (state, { payload }) => {
      return { ...state, diarizedResult: payload, getDiarizedLoadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getDiarizedFileAsync.rejected, (state) => {
      state.getDiarizedLoadingStatus = LoadingStatus.idle;
    });
    builder.addCase(getTranslationAsync.pending, (state) => {
      if (state.getDiarizedLoadingStatus === LoadingStatus.idle) {
        state.getDiarizedLoadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getTranslationAsync.fulfilled, (state, { payload }) => {
      return { ...state, translation: payload.translation, getDiarizedLoadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getTranslationAsync.rejected, (state) => {
      state.getDiarizedLoadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...analyzeSlice.actions,
};
export const { reducer } = analyzeSlice;
