import { Button, RecordStopButton } from 'components/atoms';
import { AudioFileIcon, PauseIcon, PlayIcon, ResetIcon } from 'components/atoms/svg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as MarkerPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.markers';

interface DetailedPlaybackProps {
  blob: Blob | undefined;
  websufRefCopy: object;
  exportFileLink: string;
  exportFileName: string;
  isDisabledButtons: boolean;
  onResetClick?: () => void;
  onStopClick: () => void;
  isCanStopped: boolean;
  setCurrentPlayTime?: Dispatch<SetStateAction<number | undefined>>;
}

const DetailedPlayback: FC<DetailedPlaybackProps> = ({
  blob,
  websufRefCopy,
  isDisabledButtons,
  onResetClick,
  exportFileName,
  exportFileLink,
  onStopClick,
  isCanStopped,
  setCurrentPlayTime,
}) => {
  const websufRef = useRef<WaveSurfer>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const { isMobil } = useWindowDimensions();

  const { t } = useTranslation(['stt']);

  const handlePlayPause = () => {
    if (!websufRef.current?.isPlaying()) {
      websufRef.current?.play();
      setIsPlaying(true);
    } else {
      websufRef.current?.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (blob) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      websufRef.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#4D62FF',
        cursorColor: '#4FEEE4',
        cursorWidth: 2,
        progressColor: '#4D62FF',
        barGap: 2,
        barWidth: 2,
        barHeight: 1,
        height: 40,
        plugins: [MarkerPlugin.create()],
      });
      websufRef.current.loadBlob(blob);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    websufRefCopy.current = websufRef.current;

    return () => websufRef.current?.destroy();
  }, [blob]);

  useEffect(() => {
    websufRef.current?.on('play', () => {
      setIsPlaying(true);
    });

    websufRef.current?.on('pause', () => {
      setIsPlaying(false);
    });

    websufRef.current?.on('audioprocess', (e) => {
      if (setCurrentPlayTime) setCurrentPlayTime(e);
    });

    websufRef.current?.on('interaction', () => {
      setTimeout(() => {
        if (setCurrentPlayTime) setCurrentPlayTime(websufRef.current?.getCurrentTime());
      }, 150);
    });
  }, [setCurrentPlayTime, websufRef.current]);

  return (
    <Root>
      {!isMobil && onResetClick && (
        <Button
          text={t('reset')}
          icon={<ResetIcon />}
          disabled={isDisabledButtons}
          variant="button"
          onClick={onResetClick}
        />
      )}
      <WaveformWrapper>
        {blob ? (
          <>
            {isPlaying ? (
              <PauseIconWrapper onClick={handlePlayPause}>
                <PauseIcon />
              </PauseIconWrapper>
            ) : (
              <PlayIconWrapper onClick={handlePlayPause}>
                <PlayIcon />
              </PlayIconWrapper>
            )}

            <Waveform>
              <WaveformContainer id="waveform" />
            </Waveform>
          </>
        ) : (
          <StopButtonWrapper>{isCanStopped && <RecordStopButton stopAction={onStopClick} />}</StopButtonWrapper>
        )}
      </WaveformWrapper>
      {!isMobil && (
        <Button
          text={t('exportAudio')}
          icon={<AudioFileIcon />}
          disabled={isDisabledButtons}
          variant="button"
          linkFilename={exportFileName}
          link={exportFileLink}
        />
      )}
      {isMobil && (
        <ButtonWrapper>
          {onResetClick && (
            <Button
              text={t('reset')}
              icon={<ResetIcon />}
              disabled={isDisabledButtons}
              variant="button"
              onClick={onResetClick}
              isFullWidth
            />
          )}

          <Button
            text={t('exportAudio')}
            icon={<AudioFileIcon />}
            disabled={isDisabledButtons}
            variant="button"
            isFullWidth
            linkFilename={exportFileName}
            link={exportFileLink}
          />
        </ButtonWrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 765px) {
    display: block;
  }
`;

const WaveformWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 24px;
  height: 56px;
  padding: 4px 8px;
  margin: 0 12px;

  @media (max-width: 765px) {
    margin: 0 0 8px 0;
  }
`;

const Waveform = styled.div`
  width: 100%;
  padding-left: 12px;
  overflow-x: hidden;
`;

const PlayIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  min-width: 36px;
  padding-left: 3px;

  background: ${({ theme: { colors } }) => colors.purple};
  border-radius: 50%;
`;

const PauseIconWrapper = styled(PlayIconWrapper)`
  background: ${({ theme: { colors } }) => colors.text};
  padding-left: 0;
`;

const WaveformContainer = styled.div`
  overflow-x: hidden;

  & wave {
    overflow: hidden !important;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StopButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default DetailedPlayback;
