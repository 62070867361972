import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={17} fill="#888CAB" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.5 0h-15A2.492 2.492 0 0 0 0 2.5v12C0 15.906 1.094 17 2.5 17h15c1.367 0 2.5-1.094 2.5-2.5v-12C20 1.133 18.867 0 17.5 0Zm-15 1.875h15c.313 0 .625.313.625.625v.898L11.602 8.79c-.899.742-2.344.742-3.243 0L1.875 3.4V2.5c0-.313.273-.625.625-.625Zm15 13.25h-15a.617.617 0 0 1-.625-.625V5.82l5.313 4.453A4.471 4.471 0 0 0 10 11.25a4.443 4.443 0 0 0 2.773-.977l5.352-4.453v8.68a.642.642 0 0 1-.625.625Z" />
  </svg>
);

export default SvgComponent;
