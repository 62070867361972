import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetKnowledgeResDto, UploadFilesDto, UploadFilesResponseDto } from 'api/chat.api';
import { ExtraParamsThunkType } from 'store/types';
import { RequestErrors } from 'types';

export const uploadKnowledgePdf = createAsyncThunk<
  UploadFilesResponseDto,
  UploadFilesDto,
  ExtraParamsThunkType<RequestErrors>
>('chat/upload', async (requestData, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.ChatApi.uploadFiles(requestData);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getKnowledgeAsync = createAsyncThunk<GetKnowledgeResDto, void, ExtraParamsThunkType<RequestErrors>>(
  'chat/knowledge',
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.ChatApi.getKnowledge();
      console.log(data);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
