import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.376 22c3.463 0 6.273-2.625 6.273-6V6c0-3.313-2.81-6-6.273-6-3.528 0-6.273 2.688-6.273 6v10c0 3.313 2.745 6 6.273 6Zm11.5-8.5V16c0 5.563-4.378 10.188-9.932 10.938V29h2.614c1.176 0 2.09 1 2.025 2.125 0 .5-.457.875-.98.875H7.15c-.588 0-1.046-.375-1.046-.875C6.038 30 6.953 29 8.194 29h2.614v-2.125c-5.816-.75-9.932-5.75-9.932-11.313V13.5c0-.813.653-1.5 1.568-1.5.85 0 1.568.688 1.568 1.5l-.065 2.188c0 4.187 3.463 8.062 7.776 8.312 4.966.313 9.017-3.375 9.017-8v-2.5c0-.813.653-1.5 1.568-1.5.85 0 1.568.688 1.568 1.5Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient id="a" x1={25.286} y1={27.862} x2={-11.605} y2={24.317} gradientUnits="userSpaceOnUse">
        <stop stopColor="#5D6BE9" />
        <stop offset={0.5} stopColor="#55B8FF" />
        <stop offset={1} stopColor="#32E0D6" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComponent;
