import { IconClose, InputIcon } from 'components/atoms/svg';
import { SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface ModalProps {
  setFileName: React.Dispatch<SetStateAction<string>>;
  onClose: () => void;
  onSubmit: () => void;
  time: number;
}

const Modal = (props: ModalProps) => {
  const { onClose, onSubmit, setFileName, time } = props;
  const [name, setName] = useState<string>();
  const [validate, setValidate] = useState<boolean>(false);
  const [voiceValidate, setVoiceValidate] = useState<boolean>(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('sid');
  const date = new Date();
  const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const today = date.getDate() + '. ' + monthShort[date.getMonth()] + '. ' + date.getFullYear();

  const onChange = () => {
    if (nameRef.current) {
      if (nameRef.current.value === '') {
        setValidate(true);
      } else {
        setValidate(false);
      }
      setName(nameRef.current.value);
    }
  };

  const handleSubmit = () => {
    if (name && time > 20) {
      setFileName(name);
      onSubmit();
      onClose();
    } else {
      setValidate(true);
      setVoiceValidate(true);
      if (time > 20) {
        setVoiceValidate(false);
      }
    }
  };

  return (
    <ModalDim>
      <div>
        <CloseBtn onClick={onClose}>
          <IconClose />
        </CloseBtn>
        <TitleBox>
          <h3>{t('saveAudioFileToHistory')}</h3>
          <span>{today}</span>
        </TitleBox>
        <NameInput className={validate ? 'error' : undefined}>
          <InputIcon />
          <input type="text" placeholder={t('name') ?? ''} onChange={onChange} ref={nameRef}></input>
        </NameInput>
        {voiceValidate && <span>{t('youShouldRecordMinimum20Second')}</span>}
        <BtnWrapper>
          <CancelBtn onClick={onClose}>{t('cancel')}</CancelBtn>
          <SaveBtn onClick={handleSubmit}>{t('save')}</SaveBtn>
        </BtnWrapper>
      </div>
    </ModalDim>
  );
};

const CloseBtn = styled.div`
  position: absolute;
  top: 12px;
  right: 6px;
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;
const ModalDim = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  > div {
    position: relative;
    width: 500px;
    padding: 48px 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    @media screen and (max-width: 485px) {
      width: 100%;
      padding: 36px 20px;
      margin: 0 10px;
    }
  }
`;

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    margin: 0;
  }
  span {
    font-family: 'Noto Sans KR';
    font-size: 14px;
    color: #888cab;
  }
`;
const NameInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  margin: 28px 0 14px 0;
  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid #ccddff;
  &.error {
    border-color: #ff7799;
  }
  svg {
    position: absolute;
    left: 20px;
  }
  input {
    width: 100%;
    height: 100%;
    font-family: 'Noto Sans KR';
    font-weight: 300;
    font-size: 14px;
    padding: 0 12px 0 48px;
    border: none;
    border-radius: 6px;
  }
  + span {
    font-family: 'Noto Sans KR';
    font-size: 1rem;
    color: #ff7799;
  }
`;
const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 14px 0 0 0;
  button {
    flex: 0.5;
    height: 48px;
    border-radius: 8px;
    font-family: 'Noto Sans KR';
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
`;
const CancelBtn = styled.button`
  border: 2px solid #cfd7e2;
  margin: 0 4px 0 0;
  color: #888cab;
`;
const SaveBtn = styled.button`
  background-color: #5868f5;
  margin: 0 0 0 4px;
  color: #ffffff;
`;

export default Modal;
