import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface CircleLoaderProps {
  percentage: number;
}

const CircleLoader = (props: PropsWithChildren<CircleLoaderProps>) => {
  const { children, percentage } = props;
  const refBar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const progressBar = refBar.current;
    if (percentage === 360) {
      if (progressBar) {
        progressBar.classList.remove('middle');
        progressBar.classList.add('complete');
      }
    } else if (percentage > 180) {
      if (progressBar) {
        progressBar.classList.add('middle');
      }
    }
  }, [percentage]);

  return (
    <ProgressBar ref={refBar} percentage={percentage}>
      {children}
    </ProgressBar>
  );
};

const ProgressBar = styled.div<CircleLoaderProps>`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 84px;
  height: 84px;
  border-radius: 50px;
  background: radial-gradient(closest-side, rgb(240, 241, 249) 92%, transparent 96% 100%),
    conic-gradient(rgb(255, 119, 153) var(--roll), rgb(251, 220, 230) 0);
  transition: --roll 0.4s ease-in-out;
  &.middle {
    background: radial-gradient(closest-side, rgb(240, 241, 249) 92%, transparent 96% 100%),
      conic-gradient(rgb(250, 194, 124) var(--roll), rgb(255, 220, 230) 0);
  }
  &.complete {
    background: radial-gradient(closest-side, rgb(240, 241, 249) 92%, transparent 96% 100%),
      conic-gradient(rgb(109, 196, 255) var(--roll), rgb(255, 220, 230) 0);
  }
  @property --roll {
    syntax: '<angle>';
    inherits: false;
    initial-value: 0deg;
  }
  --roll: ${(props) => props.percentage + 'deg'};
`;
export default CircleLoader;
