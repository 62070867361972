import { Diarization } from 'api/analyze.api';
import { Button, Spinner } from 'components/atoms';
import { ExportIcon } from 'components/atoms/svg';
import { selectCurrentAudioFilename } from 'features/analyze/selector';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { RealtimeSTTData } from 'pages/RealtimeSTT/RealtimeSTT';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import deleteExtensionFromFilename from 'utils/deleteExtensionFromFilename';

interface SttContentProps {
  sttData: Diarization[];
  realtimeStt?: RealtimeSTTData[];
  exportTextLink?: string;
  exportTranslationLink?: string;
  title: string;
  inputRef: RefObject<HTMLInputElement>;
  isRecordingProcess?: boolean;
  currentPlayTime?: number;
  isPostProcessing?: boolean;
}

export const bookmarksColors = [{ color: '#888CAB' }, { color: '#FFA31A' }];

const SttContent: FC<SttContentProps> = ({
  sttData,
  inputRef,
  title,
  exportTextLink,
  exportTranslationLink,
  realtimeStt,
  isRecordingProcess = false,
  currentPlayTime,
  isPostProcessing = false,
}) => {
  const { t } = useTranslation(['stt']);
  const { isMobil } = useWindowDimensions();

  const currentAudioFilename = useSelector(selectCurrentAudioFilename);
  const [scrolledUp, setScrolledUp] = useState<boolean>(false);
  const [showExportMenu, setShowExportMenu] = useState<boolean>(false);

  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bottomRef.current && isRecordingProcess) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    if (topRef.current && !isRecordingProcess && !scrolledUp) {
      topRef.current?.scrollIntoView({ behavior: 'smooth' });
      setScrolledUp(true);
    }
  }, [sttData, isRecordingProcess]);

  return (
    <Root>
      <Headed>
        <InputWrapper>
          <TitleInput value={title} ref={inputRef} />
        </InputWrapper>
        {isMobil ? (
          <ExportButtonsWrapperMobile>
            <div onClick={() => setShowExportMenu(!showExportMenu)}>
              <ExportIcon />
            </div>
            {showExportMenu && (
              <ExportMenu>
                <ExportLinkButton
                  download={deleteExtensionFromFilename(currentAudioFilename) + '.txt'}
                  href={exportTextLink}
                  type="text/plain">
                  {t('exportText')}
                </ExportLinkButton>

                <ExportLinkButton
                  download={deleteExtensionFromFilename(currentAudioFilename) + '-translation.txt'}
                  href={exportTranslationLink}
                  type="text/plain">
                  {t('translation')}
                </ExportLinkButton>
              </ExportMenu>
            )}
          </ExportButtonsWrapperMobile>
        ) : (
          <ExportButtonsWrapper>
            <Button
              text={t('exportText')}
              icon={<ExportIcon />}
              variant="button"
              link={exportTextLink}
              disabled={!exportTextLink}
              linkFilename={deleteExtensionFromFilename(currentAudioFilename) + '.txt'}
              downloadFileType="text/plain"
            />

            <Button
              text={t('translation')}
              icon={<ExportIcon />}
              variant="button"
              link={exportTranslationLink}
              disabled={!exportTranslationLink}
              linkFilename={deleteExtensionFromFilename(currentAudioFilename) + '-translation.txt'}
              downloadFileType="text/plain"
            />
          </ExportButtonsWrapper>
        )}
      </Headed>
      <Lines>
        <div ref={topRef}></div>

        {sttData?.length
          ? sttData.map((sttItem) => (
              <LineWrapper key={sttItem.start}>
                <SpeakerName>
                  {sttItem.speakerName ? sttItem.speakerName : `Speaker ${sttItem.speakerId + 1}`}
                </SpeakerName>
                <TextWrapper>
                  <Text>
                    {sttItem.words.map((word: string, index: number) => (
                      <Word $isHighlight={!!currentPlayTime && currentPlayTime > sttItem.start} key={word + index}>
                        {word}
                        &nbsp;
                      </Word>
                    ))}
                  </Text>
                </TextWrapper>
              </LineWrapper>
            ))
          : realtimeStt?.map((realtimeSttItem: RealtimeSTTData, index: number) => (
              <LineWrapper key={`${index} ${realtimeSttItem.speaker}`}>
                <SpeakerName>{`Speaker ${realtimeSttItem.speaker + 1}`}</SpeakerName>
                <TextWrapper>
                  <Text>{realtimeSttItem.words.join('  ')}</Text>
                </TextWrapper>
              </LineWrapper>
            ))}

        <div ref={bottomRef}></div>
      </Lines>

      {isPostProcessing && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
`;

const Headed = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 12px;

  @media screen and (max-width: 840px) {
    margin-bottom: 12px;

    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  @media (max-width: 765px) {
    flex-direction: row;
  }
`;

const Lines = styled.div`
  max-width: 90vw;
  overflow-y: auto;
`;

const LineWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const SpeakerName = styled.div`
  flex: 1 0 140px;
  max-width: 140px;
  ${({ theme: { typography } }) => typography.text};
  color: ${({ theme: { colors } }) => colors.textLight};

  @media (max-width: 1140px) {
    max-width: 108px;
  }

  @media (max-width: 765px) {
    flex: 1 0 96px;
    max-width: 96px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  color: ${({ theme: { colors } }) => colors.accent};
  ${({ theme: { typography } }) => typography.text};
  overflow-wrap: anywhere;
`;

const Word = styled.span<{ $isHighlight: boolean }>`
  background: ${({ $isHighlight, theme: { colors } }) => ($isHighlight ? colors.cian : 'none')};
`;

const TitleInput = styled.input`
  display: flex;
  border: none;
  outline: 1px solid ${({ theme: { colors } }) => colors.purple};
  width: max-content;
  border-radius: 4px;
  padding: 4px;
  background: ${({ theme: { colors } }) => colors.bckg};
  // max-width: 250px;
  margin-right: 12px;

  &:read-only {
    cursor: default;
    outline: none;
  }

  @media (max-width: 765px) {
    max-width: 130px;
  }

  @media (max-width: 400px) {
    max-width: 114px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ExportButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const ExportButtonsWrapperMobile = styled.div`
  position: relative;
`;
const ExportMenu = styled.div`
  position: absolute;
  right: 0;
  width: max-content;
  padding: 8px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: end;
  background-color: ${({ theme }) => theme.colors.bckg};
  box-shadow: ${({ theme }) => `2px 2px 10px 5px ${theme.colors.border}`};
  border-radius: 4px;
`;

const ExportLinkButton = styled.a`
  text-decoration: none;
  ${({ theme: { typography } }) => typography.link}
`;

export default SttContent;
