import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface MicrophoneAudioVisualizer {
  className?: string;
}

const MicrophoneAudioVisualizer: FC<MicrophoneAudioVisualizer> = ({ className }) => {
  const [waveElements] = useState<Array<JSX.Element>>(
    Array.from(Array(64), (_, index) => {
      return <Wave key={index} />;
    }),
  );

  const array = useRef(new Uint8Array(96));
  const rootRef = useRef<HTMLDivElement>(null);
  const audioContext = useRef(new AudioContext());
  const audioAnalyser = useRef(audioContext.current.createAnalyser());

  // const

  useEffect(() => {
    let interval: NodeJS.Timer;
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        const src = audioContext.current.createMediaStreamSource(stream);
        src.connect(audioAnalyser.current);

        interval = setInterval(() => {
          audioAnalyser.current.getByteFrequencyData(array.current);
          if (rootRef.current) {
            const elements = rootRef.current.children as HTMLCollectionOf<HTMLDivElement>;
            for (let i = 0; i < 64; i++) {
              elements.item(i)!.style.height = String(array.current[32 + i] / 4) + 'px';
            }
          }
        }, 50);
      });

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Root className={className} ref={rootRef}>
      {waveElements}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: max-content;
  padding: 0 12px;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 24px;
`;

const Wave = styled.div`
  background: #4d62ff;
  border-radius: 2.5px;
  min-height: 1px;
  width: 2px;
`;

export default MicrophoneAudioVisualizer;
