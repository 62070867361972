import logoImageDesktop from 'assets/images/logoDesktop.png';
import logoImageMobile from 'assets/images/logoMobile.png';
import { AuthorizedRoutes, corporateWebsiteLink } from 'constants/routes';
import { actions as authAction } from 'features/auth';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled, { css } from 'styled-components';
const Header = () => {
  const { isMobil } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = useState('stt');
  const signOutHandler = () => {
    dispatch(authAction.signOut());
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('gpt')) {
      setCurrentRoute('gpt');
    } else if (location.pathname.includes('sid')) {
      setCurrentRoute('sid');
    } else {
      setCurrentRoute('stt');
    }
  }, [location]);
  return (
    <Root>
      <Logo to={corporateWebsiteLink}>
        <img src={isMobil ? logoImageMobile : logoImageDesktop} width={isMobil ? 140 : 240} alt="" />
      </Logo>
      <Logout onClick={signOutHandler}>{t('logout')}</Logout>
      <ServiceButtonWrapper>
        <ServiceButton selected={currentRoute === 'stt'} onClick={() => navigate(AuthorizedRoutes.REAL_STT)}>
          Real-STT
        </ServiceButton>
        <ServiceButton
          selected={currentRoute === 'sid'}
          onClick={() => navigate(AuthorizedRoutes.REAL_SID_NEW_SPEAKER)}>
          Real-SID
        </ServiceButton>
        <ServiceButton selected={currentRoute === 'gpt'} onClick={() => navigate(AuthorizedRoutes.REAL_GPT)}>
          Real-GPT
        </ServiceButton>
      </ServiceButtonWrapper>
    </Root>
  );
};
const Root = styled.header`
  display: flex;
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid #d2dbec;
  position: relative;
  @media (max-width: 765px) {
    padding: 8px 0;
    padding-bottom: 8px;
  }
`;
const Logo = styled(Link)`
  margin-right: auto;
  @media (max-width: 765px) {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-bottom: 25px;
  }
`;
const Logout = styled.div`
  &:hover {
    color: ${({ theme: { colors } }) => colors.purple};
  }
  @media (max-width: 765px) {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
  }
`;
const ServiceButtonWrapper = styled.div`
  position: absolute;
  bottom: -1px;
  right: 0;
  gap: 8px;
  display: flex;
  @media (max-width: 765px) {
    display: flex;
    align-items: center;
  }
`;
const ServiceButton = styled.button<{ selected: boolean }>`
  font-weight: 400;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.purple};
  }
  @media (max-width: 765px) {
    font-weight: 400;
    font-size: 12px;
  }
  ${({ selected }) =>
    selected
      ? css`
          border: 1px solid #d2dbec;
          border-bottom: 1px solid #ffffff;
          padding: 12px;
          border-radius: 8px 8px 0px 0px;
        `
      : ''}
`;
export default Header;
