import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={19} fill="#5868F5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.473 5.233a1.004 1.004 0 0 1 0-1.422L6.99.295a1.004 1.004 0 0 1 1.422 0l3.516 3.516a1.002 1.002 0 0 1 0 1.422 1.013 1.013 0 0 1-1.423 0l-1.8-1.799v6.947a1.004 1.004 0 1 1-2.008 0V3.434l-1.8 1.8a1.004 1.004 0 0 1-1.423 0Zm10.253 1.8h-2.009a1.004 1.004 0 0 0 0 2.009h1.674v7.7H2.01v-7.7h1.674a1.004 1.004 0 0 0 0-2.01h-2.01A1.682 1.682 0 0 0 0 8.708v8.37a1.682 1.682 0 0 0 1.674 1.673h12.052a1.682 1.682 0 0 0 1.674-1.674v-8.37a1.682 1.682 0 0 0-1.674-1.673Z" />
  </svg>
);

export default SvgComponent;
