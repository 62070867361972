import { Dispatch, SetStateAction } from 'react';

export default function makeTextFile(
  textData: Array<{ speaker: number; words: string[]; isBookmarked: boolean; start: number }>,
  downloadLink: string,
  setDownloadLink: Dispatch<SetStateAction<string>>,
) {
  const data = textData.map((d) => `Speaker ${d.speaker + 1}: ${d.words.join(' ')}`).join('\n');
  const blob = new Blob(['\ufeff', data], { type: 'text/plain;charset=utf-8' });

  if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

  setDownloadLink(window.URL.createObjectURL(blob));
}
