import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from 'components/atoms';
import { ArrowIcon, EditIcon, TrashIcon } from 'components/atoms/svg';
import { AuthorizedRoutes } from 'constants/routes';
import { actions as speakersAction } from 'features/speakers';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';

import { deleteSpeakerAsync } from '../../../../features/speakers/thunks';
import { notifyErr, notifySuc } from '../../../../utils/notification';
interface SpeakerItemProps {
  name: string;
}

const SpeakerItem: FC<SpeakerItemProps> = ({ name }) => {
  const { t } = useTranslation('sid');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const { isMobil } = useWindowDimensions();

  const deleteSpeakerHandler = () => {
    dispatch(deleteSpeakerAsync(name))
      .then(unwrapResult)
      .then(() => {
        notifySuc(t('successMessageDeleteSpeaker'));
      })
      .catch(() => {
        notifyErr(t('errorMessageDeleteSpeaker'), '11');
      });
  };

  const verifySpeakerHandler = () => {
    dispatch(speakersAction.setVerificationSpeaker(name));
    localStorage.setItem('verification-speaker', name);
    navigate(AuthorizedRoutes.REAL_SID_PROCESS_PAGE);
  };

  if (isMobil) {
    return (
      <Root>
        <Item onClick={() => setIsOpen((prev) => !prev)}>
          {name} <StyledArrowIcon $isOpen={isOpen} />
        </Item>
        {isOpen && (
          <ButtonWrapper>
            <StyledButton text={t('verify')} onClick={verifySpeakerHandler} />
            <ActionButtonWrapper>
              <EditButtonWrapper>
                <EditIcon fill="#888CAB" />
              </EditButtonWrapper>
              <DeleteButtonWrapper onClick={deleteSpeakerHandler}>
                <TrashIcon />
              </DeleteButtonWrapper>
            </ActionButtonWrapper>
          </ButtonWrapper>
        )}
      </Root>
    );
  }

  return (
    <Root>
      <Item>{name}</Item>

      <ButtonWrapper>
        <StyledButton text={t('verify')} onClick={verifySpeakerHandler} />
        <EditButtonWrapper>
          <EditIcon fill="#888CAB" />
        </EditButtonWrapper>
        <DeleteButtonWrapper onClick={deleteSpeakerHandler}>
          <TrashIcon />
        </DeleteButtonWrapper>
      </ButtonWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 765px) {
    flex-direction: column;
    background: ${({ theme: { colors } }) => colors.lightBckg};
    border-radius: 24px;
  }
`;

const Item = styled.div`
  flex-grow: 1;
  ${({ theme: { typography } }) => typography.text};
  background: ${({ theme: { colors } }) => colors.lightBckg};
  padding: 16px 24px;
  border-radius: 24px;
  color: ${({ theme: { colors } }) => colors.accent};
  font-weight: 500;

  @media (max-width: 765px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;

  @media (max-width: 765px) {
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
    padding: 16px;
  }
`;

const EditButtonWrapper = styled.button`
  padding: 8px;
  cursor: pointer;
  margin-right: 12px;
`;

const DeleteButtonWrapper = styled.button`
  padding: 8px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  border-radius: 24px;
  margin-right: 12px;
`;

const StyledArrowIcon = styled(ArrowIcon)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')});
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default SpeakerItem;
