import { unwrapResult } from '@reduxjs/toolkit';
import IconMessagePNG from 'assets/images/dawini.png';
import { Button, InputButton, Spinner } from 'components/atoms';
import { UploadIcon } from 'components/atoms/svg';
import { ChatInput } from 'components/molecules';
import { gaMeasurementId } from 'config';
import { actions as authAction } from 'features/auth';
import { actions } from 'features/chat';
import { selectUploadFiles } from 'features/chat/selector';
import { getKnowledgeAsync, uploadKnowledgePdf } from 'features/chat/thunks';
import React, { ChangeEvent, FC, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { notifyErr, notifySuc } from 'utils/notification';

interface RagProps {
  sessionId: string;
  model: string;
  useHyde: boolean;
  onFileUploaded: () => void; // New prop for file upload callback
}

ReactGA.initialize(gaMeasurementId);

const Rag: FC<RagProps> = ({ sessionId, model, useHyde, onFileUploaded }) => {
  const { t } = useTranslation(['gpt', 'auth']);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState('');
  const dispatch = useAppDispatch();
  const [uploaded, setUploaded] = useState(false);
  const [normalChat, setNormalChat] = useState(false);
  const uploadFiles = useSelector(selectUploadFiles);

  const fetchBlob = async (fileLinks: Array<File>) => {
    console.log(`Uploading ${fileLinks.length} files`);
    const formData = new FormData();
    for (const file of fileLinks) {
      const blobLink = URL.createObjectURL(file);
      const response = await fetch(blobLink);
      const responseBlob = await response.blob();

      const temp = new File([responseBlob], file.name, {
        type: file.type,
      });

      dispatch(actions.uploadFiles([...uploadFiles, file.name]));
      formData.append('file', temp);
      console.log('Adding ', temp.name);
    }

    dispatch(
      uploadKnowledgePdf({
        formData,
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(getKnowledgeAsync());
        notifySuc(t('fileUploadSuccess'));
        setIsLoading(false);
        setUploaded(true);
        onFileUploaded(); // Notify parent component about the file upload
      })
      .catch(() => {
        setIsLoading(false);
        notifyErr(t('fileUploadError'), '10');
      });
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (localStorage.getItem('persist:auth')) {
      const Authorized = JSON.parse(localStorage.getItem('persist:auth')!).isAuthorized;
      if (Authorized === 'false') {
        notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
        dispatch(authAction.signOut());
        return;
      }
    } else {
      notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
      dispatch(authAction.signOut());
      return;
    }
    const { files } = event.target;
    setNormalChat(false);

    if (!files) {
      setFileUploadError(t('fileUploadError') as string);
      return;
    }

    if (files.length > 10) {
      setFileUploadError(t('maxUploadFiles') as string);
      return;
    }

    // 14MB
    const FILESIZE_LIMIT = 14000000;
    const fileLinks: File[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > FILESIZE_LIMIT) {
        setFileUploadError(t('fileUploadErrorFileTooBig') as string);
        return;
      }
      const acceptedTypes = ['application/pdf', 'application/zip', 'text/plain', 'application/x-zip-compressed'];
      if (!acceptedTypes.includes(file.type)) {
        console.log('File type is: ', file.type);
        setFileUploadError(t('incorrectFileError') as string);
        return;
      }

      fileLinks.push(file);

      setFileUploadError('');
      setIsLoading(true);
    });
    fetchBlob(fileLinks);
  };

  const selectNormalChat = () => {
    ReactGA.send({ hitType: 'event', page: '/gpt', title: 'GPT Demo - RAG OFF' });
    setNormalChat(true);
  };

  return (
    <Root>
      {!normalChat && !uploaded && !isLoading && (
        <ContextWrapper>
          <InputButton
            text={t('uploadPdf')}
            icon={<UploadIcon />}
            accept=".pdf,.zip,.txt"
            onChange={onLoadFile}
            errorMessage={fileUploadError}
            align-items={'center'}
            multiple
          />
          <Icon />
          <Button text={t('chatToDawini')} data-cy="start-chatting" onClick={() => selectNormalChat()}></Button>
        </ContextWrapper>
      )}
      {isLoading && (
        <ContextWrapper>
          <Absolute>
            <Center>
              <Spinner />
            </Center>
          </Absolute>
        </ContextWrapper>
      )}
      {(normalChat || uploaded) && !isLoading && (
        <ChatInput rag={!normalChat} sessionId={sessionId} model={model} useHyde={useHyde} />
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  min-height: calc(100vh - 300px);

  flex-direction: column;
  gap: 20px;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 12px;
  box-sizing: border-box;

  @media (max-width: 765px) {
    gap: 10px;
  }
  /*
  @media (max-width: 765px) {
    width: 375px;
  } */
`;

const Icon = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  font-size: 30px;

  background: url(${IconMessagePNG});
`;

const Absolute = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(226, 229, 244, 0.2);
`;

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 600px);
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  margin: 0 auto; /* Center horizontally */
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 765px) {
    min-height: 350px;
    margin: 0;
    padding: 2px;
  }
`;

export default Rag;
