import IconMessagePNG from 'assets/images/dawini.png';
import { FC } from 'react';
import styled from 'styled-components';

interface AIMessageProps {
  text: string;
  messageKey: number;
}

const AIMessage: FC<AIMessageProps> = ({ text, messageKey }) => {
  return (
    <Root>
      <Icon />
      <Text data-cy={`ai-message-${messageKey}`}>{text}</Text>
    </Root>
  );
};

const Root = styled.div`
  position: relative;

  margin-bottom: 8px;

  max-width: 75%;
`;

const Icon = styled.div`
  position: absolute;

  height: 32px;
  width: 32px;

  background: white url(${IconMessagePNG}) 70% 60%/24px 21.6px no-repeat;
  border-radius: 50%;
`;

const Text = styled.div`
  padding-top: 4px;
  padding-left: 44px;
  border-radius: 12px 2px 12px 12px;
  ${({ theme: { typography } }) => typography.text};
`;

export default AIMessage;
