import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { AnalyzeStore } from './types';

export const selectAnalyze = (state: RootState): AnalyzeStore => state.analyze;

export const selectDiarizedResult = createSelector(selectAnalyze, (analyze) => analyze.diarizedResult);
export const selectDiarizedResultLoadingStatus = createSelector(
  selectAnalyze,
  (analyze) => analyze.getDiarizedLoadingStatus,
);
export const selectCurrentAudioFilename = createSelector(selectAnalyze, (analyze) => analyze.currentAudioFilename);
export const selectRealtimeStt = createSelector(selectAnalyze, (analyze) => analyze.realtimeStt);
export const selectTranslation = createSelector(selectAnalyze, (analyze) => analyze.translation);
