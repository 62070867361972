import { AxiosResponse } from 'axios';
import { sttHttp } from 'services/http';

import { ResponseUnionType } from '../types';

export type Diarization = {
  speakerId: number;
  speakerName: string;
  words: Array<string>;
  duration: number;
  start: number;
  end: number;
  key: string;
  isBookmarked: boolean;
};

export interface DiarizedResponseDto {
  diarization: Diarization[];
  summary: string;
  keywords: Array<string>;
  transcript: string;
}

export interface DiarizeRequestDto {
  formData: FormData;
  language: string;
}

export interface TranslationResponseDto {
  translation: string;
}

export interface TranslationRequestDto {
  originalText: string[];
  sourceLanguage: string;
  targetLanguage: string;
}

export const AnalyzeApi = {
  requestDiarization: async (
    data: DiarizeRequestDto,
  ): Promise<AxiosResponse<ResponseUnionType<DiarizedResponseDto>>> => {
    return sttHttp.post(`/analyze?language=${data.language}`, data.formData, {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
    });
  },
  getTranslation: async (
    data: TranslationRequestDto,
  ): Promise<AxiosResponse<ResponseUnionType<TranslationResponseDto>>> => {
    return sttHttp.post('/analyze/translate', data);
  },
};
