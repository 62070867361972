import { AuthorizedRoutes } from 'constants/routes';
import { MainLayout } from 'layouts';
import { RagPage } from 'pages/GPTPage';
import { NewSpeakerPage } from 'pages/NewSpeakerPage';
import { RealtimeSTT } from 'pages/RealtimeSTT';
import { SpeakersListPage } from 'pages/SpeakersListPage';
import { VoiceVerificationPage } from 'pages/VoiceVerificationPage';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { SpeakerVerifyProtector } from './protectors';

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: AuthorizedRoutes.REAL_STT,
        element: <RealtimeSTT />,
      },
      {
        path: AuthorizedRoutes.REAL_GPT,
        element: <RagPage />,
      },
      {
        path: AuthorizedRoutes.REAL_SID,
        element: <SpeakersListPage />,
      },
      {
        element: <SpeakerVerifyProtector />,
        children: [
          {
            path: AuthorizedRoutes.REAL_SID_PROCESS_PAGE,
            element: <VoiceVerificationPage />,
          },
        ],
      },
      {
        path: AuthorizedRoutes.REAL_SID_NEW_SPEAKER,
        element: <NewSpeakerPage />,
      },
      { path: '*', element: <Navigate to={AuthorizedRoutes.REAL_STT} /> },
    ],
  },
]);

const AuthorizedRouting = () => {
  return <RouterProvider router={router} />;
};

export default AuthorizedRouting;
