import { Header } from 'components/molecules';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const MainLayout = () => {
  return (
    <Root>
      <Container>
        <Header />
        <Main>
          <Outlet />
        </Main>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  padding-top: 0 20px;
  overflow: hidden;

  @media (max-width: 765px) {
    padding: 0 10px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  max-height: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  padding-top: 10px;
  height: calc(var(--vh, 1vh) * 100 - 114px);
  overflow: hidden;

  @media (max-height: 850px) {
    padding-top: 8px;
  }

  @media (max-width: 765px) {
    height: calc(var(--vh, 1vh) * 100 - 77px);
    padding-top: 0px;
  }
`;

export default MainLayout;
