import { FC, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { ButtonVariant } from '../Button/Button';

interface InputButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
  icon?: ReactNode;
  variant?: ButtonVariant;
  errorMessage?: string;
}

const InputButton: FC<InputButtonProps> = ({ text, icon = false, variant = 'submit', errorMessage, ...rest }) => {
  return (
    <>
      <Root id="FileInput" type="file" {...rest} />
      <LabelWrapper>
        <Label $variant={variant} htmlFor="FileInput">
          <Icon>{icon}</Icon>
          {text}
        </Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelWrapper>
    </>
  );
};

const Root = styled.input`
  display: none;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

const Label = styled.label<{ $variant: ButtonVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, $variant }) => ($variant === 'submit' ? theme.colors.purple : theme.colors.lightBckg)};
  padding: 12px 20px;
  border-radius: ${({ $variant }) => ($variant === 'submit' ? '8px' : '24px')};
  height: max-content;
  white-space: wrap;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme, $variant }) => ($variant === 'submit' ? theme.colors.white : theme.colors.text)};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const ErrorMessage = styled.span`
  ${({ theme: { typography } }) => typography.smallText};
  color: ${({ theme: { colors } }) => colors.red};
`;

export default InputButton;
