import { AuthorizedRoutes } from 'constants/routes';
import { selectVerificationSpeaker } from 'features/speakers/selectors';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const SpeakerVerifyProtector = () => {
  const verificationSpeaker = useSelector(selectVerificationSpeaker) || localStorage.getItem('verification-speaker');

  if (verificationSpeaker) {
    return <Outlet />;
  }

  return <Navigate to={AuthorizedRoutes.REAL_SID} />;
};

export default SpeakerVerifyProtector;
