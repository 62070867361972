export const sttServerString = process.env.REACT_APP_STT_API_DOMAIN;
export const gptServerString = process.env.REACT_APP_GPT_API_DOMAIN;
export const sidServerString = process.env.REACT_APP_SID_API_DOMAIN;

export const authServerString = process.env.REACT_APP_AUTH_API_DOMAIN;
export const wsString = 'wss://' + process.env.REACT_APP_API_DOMAIN;
export const deepgramWsKoString =
  'wss://deepgram.darwinai.link/v1/listen/stream?language=ko&interim_results=true&model=nova-2-general&punctuate=true&numerals=true&smart_format=true';
export const deepgramWsJaString =
  'wss://deepgram.darwinai.link/v1/listen/stream?language=ja&interim_results=true&model=nova-2-general&punctuate=true&numerals=true';
export const deepgramWsRuString =
  'wss://deepgram.darwinai.link/v1/listen/stream?language=ru&interim_results=true&model=nova-2-general&punctuate=true&numerals=true';
export const deepgramWsZhTWString =
  'wss://deepgram.darwinai.link/v1/listen/stream?language=zh-TW&interim_results=true&model=nova-2-general&punctuate=true&numerals=true';

export const phonexiaServerString = 'https://' + process.env.REACT_APP_PHONEXIA_URL;
export const wsPhonexiaString = 'wss://' + process.env.REACT_APP_PHONEXIA_URL_WEBSOCKET;
export const phonexiaSessionId = process.env.REACT_APP_PHONEXIA_SESSION_ID;

export const emailJSServiceId = process.env.REACT_APP_SERVICE_ID || '';
export const emailJSPublicKey = process.env.REACT_APP_PUBLIC_KEY || '';
export const emailJSTemplateId = process.env.REACT_APP_TEMPLATE_ID || '';

export const verificationTokenURL = process.env.REACT_APP_VERIFICATION_TOKEN_URL;

export const lastUpdated = process.env.REACT_APP_LAST_UPDATED || '';
export const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
