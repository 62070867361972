import { PauseIcon, PlayIcon } from 'components/atoms/svg';
import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import WaveSurfer from 'wavesurfer.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface PlaybackProps {
  blob: Blob | undefined;
}

const Playback: FC<PlaybackProps> = ({ blob }) => {
  const websufRef = useRef<WaveSurfer>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handlePlayPause = () => {
    if (!websufRef.current?.isPlaying()) {
      websufRef.current?.play();
      setIsPlaying(true);
    } else {
      websufRef.current?.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (blob) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      websufRef.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#4D62FF',
        cursorColor: '#83c7f4',
        cursorWidth: 2,
        progressColor: '#4D62FF',
        barGap: 2,
        barWidth: 2,
        barMinHeight: 1,
        height: 40,
      });
      websufRef.current.loadBlob(blob);
    }

    return () => websufRef.current?.destroy();
  }, [blob]);

  useEffect(() => {
    websufRef.current?.on('play', () => {
      setIsPlaying(true);
    });

    websufRef.current?.on('pause', () => {
      setIsPlaying(false);
    });
  }, [websufRef.current]);

  return (
    <Root>
      <WaveformWrapper>
        <>
          {isPlaying ? (
            <PauseIconWrapper onClick={handlePlayPause}>
              <PauseIcon />
            </PauseIconWrapper>
          ) : (
            <PlayIconWrapper onClick={handlePlayPause}>
              <PlayIcon />
            </PlayIconWrapper>
          )}

          <Waveform>
            <WaveformContainer id="waveform" />
          </Waveform>
        </>
      </WaveformWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 765px) {
    display: block;
  }
`;

const WaveformWrapper = styled.div`
  display: flex;
  /* width: 100%; */
  width: 278px;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 24px;
  height: 56px;
  padding: 4px 8px;
  margin: 0 12px;

  @media (max-width: 765px) {
    margin: 0 0 8px 0;
  }
`;

const Waveform = styled.div`
  width: 100%;
  padding-left: 12px;
  overflow-x: hidden;
`;

const PlayIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  min-width: 36px;
  padding-left: 3px;

  background: ${({ theme: { colors } }) => colors.purple};
  border-radius: 50%;
`;

const PauseIconWrapper = styled(PlayIconWrapper)`
  background: ${({ theme: { colors } }) => colors.text};
  padding-left: 0;
`;

const WaveformContainer = styled.div`
  overflow-x: hidden;

  & wave {
    overflow: hidden !important;
  }
`;

export default Playback;
