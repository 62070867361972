import { unwrapResult } from '@reduxjs/toolkit';
import { SignInResDto } from 'api/auth.api';
import { Button } from 'components/atoms';
import { LockIcon, UserIcon } from 'components/atoms/svg';
import { InputField } from 'components/molecules';
import { lastUpdated } from 'config';
import { AuthorizedRoutes, UnauthorizedRoutes } from 'constants/routes';
import { selectIsVerified, selectLoadingStatus } from 'features/auth/selectors';
import { signInAsync } from 'features/auth/thunks';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';

import { notifyErr } from '../../../../utils/notification';

interface FormValue {
  email: string;
  password: string;
}

const LoginForm = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const isVerified = useSelector(selectIsVerified);

  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
  });

  const onSubmit = async (data: FormValue) => {
    await dispatch(signInAsync(data))
      .then((response) => {
        unwrapResult(response);
        return response.payload;
      })
      .then((response) => {
        if (isVerified) {
          const previousPath = window.localStorage.getItem('previousPath');
          if (previousPath) {
            navigate(window.localStorage.getItem('previousPath')!);
          } else {
            navigate(AuthorizedRoutes.REAL_STT);
          }
        } else if ((response as SignInResDto).data.userId) {
          navigate(UnauthorizedRoutes.VERIFICATION);
        }
      })
      .catch((error) => {
        if (error?.statusCode && error.statusCode === 401) {
          notifyErr(t('errorMessageAuthorizationIncorrectData'), '1');
        } else {
          notifyErr(t('errorMessageAuthorization'), '2');
        }
      });
  };

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Title>{t('login')}</Title>
      <FieldWrapper>
        <InputField
          control={control}
          name="email"
          rules={{ required: { value: true, message: 'Required field' } }}
          label={t('login')}
          icon={<UserIcon />}
        />
      </FieldWrapper>
      <FieldWrapper $mb={24}>
        <InputField
          type="password"
          rules={{ required: { value: true, message: 'Required field' } }}
          control={control}
          name="password"
          label={t('password')}
          icon={<LockIcon />}
        />
      </FieldWrapper>
      <StyledButton text={t('login').toUpperCase()} disabled={isLoading} />
      <LinkWrapper>
        <Text>{t('dontHaveUserID')}</Text>
        <StyledLink to={UnauthorizedRoutes.SIGN_UP}>{t('createAnAccount')}</StyledLink>
      </LinkWrapper>
      <RightsText>© 2024 DARWIN INTELLIGENCE. All rights reserved</RightsText>
      <RightsText>Last Updated: {lastUpdated}</RightsText>
    </Root>
  );
};

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;

  @media (max-width: 765px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.h1}
`;

const FieldWrapper = styled.div<{ $mb?: number }>`
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : '16px')};
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
`;

const Text = styled.span`
  ${({ theme }) => theme.typography.text};
  margin-right: 8px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;

  @media (max-width: 765px) {
    margin-bottom: 52px;
    row-gap: 12px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  flex: 1 0 50%;
`;

const RightsText = styled.div`
  ${({ theme }) => theme.typography.smallText};
  margin-top: auto;

  @media (max-width: 765px) {
    text-align: center;
  }
`;

export default LoginForm;
