import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="15" viewBox="0 0 20 15" fill="#888CAB" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13 13.5V11.5H17V13.5H13ZM13 5.5V3.5H20V5.5H13ZM13 9.5V7.5H19V9.5H13ZM1 3.5H0V1.5H4V0H8V1.5H12V3.5H11V12.5C11 13.05 10.8043 13.521 10.413 13.913C10.021 14.3043 9.55 14.5 9 14.5H3C2.45 14.5 1.979 14.3043 1.587 13.913C1.19567 13.521 1 13.05 1 12.5V3.5ZM3 3.5V12.5H9V3.5H3Z" />
  </svg>
);

export default SvgComponent;
