import { IconRefresh } from 'components/atoms/svg';
import { gaMeasurementId } from 'config';
import { AuthorizedRoutes } from 'constants/routes';
import { actions as authAction } from 'features/auth';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { notifyErr } from 'utils/notification';
import { v4 as uuidv4 } from 'uuid';

import { GPT } from './components';

ReactGA.initialize(gaMeasurementId);

const llama_70b = 'llama3-70b-8192';

const GPTPage = () => {
  const { t } = useTranslation(['gpt', 'auth']);
  const [sessionId, setSessionId] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fileUploaded, setFileUploaded] = useState(false); // State to track if a file is uploaded
  ReactGA.send({ hitType: 'pageview', page: '/gpt', title: 'GPT Demo' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clearSession = () => {
    if (localStorage.getItem('persist:auth')) {
      const Authorized = JSON.parse(localStorage.getItem('persist:auth')!).isAuthorized;
      if (Authorized === 'true') {
        window.location.reload();
        navigate(AuthorizedRoutes.REAL_GPT);
      } else if (Authorized === 'false') {
        notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
        dispatch(authAction.signOut());
      }
    } else {
      notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
      dispatch(authAction.signOut());
    }
  };

  useEffect(() => {
    const uuid = uuidv4();
    setSessionId(uuid);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFileUploaded = () => {
    setFileUploaded(true);
  };

  return (
    <Root>
      <Header>
        <div>
          <h1>{t('chat')}</h1>
          <h5>
            {t('ragExplanation') + ' ' + t('modelExplanationLarge')}
            {navigator.language == 'ko' && '\n'} {t('littleTipForUsingRAG')}
          </h5>
          <RefreshChatButton onClick={() => clearSession()}>
            <RefreshChatButtonText>{t('newChat')}</RefreshChatButtonText>
            <IconRefresh />
          </RefreshChatButton>
        </div>
      </Header>
      <GPT sessionId={sessionId} model={llama_70b} useHyde={true} onFileUploaded={handleFileUploaded} />
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  min-height: 95%;
  max-height: 100%;
  margin-bottom: 20px;
  overflow-y: scroll; /* Vertical scroll */

  grid-template-areas:
    'header'
    'content';

  @media (max-height: 850px) {
    padding-bottom: 10px;
  }

  @media (max-width: 765px) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 765px) and (orientation: portrait) {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 16px;
  > div:first-child {
    display: flex;
    flex-flow: column;
  }
  > div:nth-child(2) {
    display: flex;
    margin-right: 28px;
  }
  h1 {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 1.5rem;
    color: #1a2b7c;
    margin: 0 0 16px 0;
  }
  h5 {
    max-width: 760px;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 850px) {
    margin-top: 0;
    flex-flow: column;
    align-items: flex-start;
    > div:first-child {
      h1 {
        margin: 16px 0;
      }
      h5 {
        white-space: inherit;
      }
    }
    > div:nth-child(2) {
      margin-right: 0;
      justify-content: flex-end;
    }
  }
  @media (max-width: 765px) {
    margin-bottom: 2px;
  }
`;

const RefreshChatButton = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.blue};
  cursor: pointer;
`;

const RefreshChatButtonText = styled.div`
  ${({ theme: { typography } }) => typography.text};
  color: ${({ theme: { colors } }) => colors.blue};
  margin-right: 8px;
`;

export default GPTPage;
