import styled, { keyframes } from 'styled-components';

import { SpinnerIcon } from '../svg';

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <SpinnerIcon />
    </SpinnerWrapper>
  );
};

const rotation = keyframes`
  0% {
    transform: rotate(0);
  } 100% {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotation} 1.6s linear infinite;
`;

export default Spinner;
