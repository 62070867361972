import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'types';

import { getKnowledgeAsync, uploadKnowledgePdf } from './thunks';
import { ChatStore } from './types';

const initialState: ChatStore = {
  uploadFiles: [],
  uploadResult: undefined,
  knowledge: undefined,
  loadingStatus: LoadingStatus.idle,
  rag: false,
  error: null,
};

const chatsSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    uploadFiles: (state, { payload }: PayloadAction<string[]>) => {
      return { ...state, uploadFiles: payload };
    },
    updateRag: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, rag: payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKnowledgeAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(getKnowledgeAsync.fulfilled, (state, { payload }) => {
      return { ...state, knowledge: payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(getKnowledgeAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(uploadKnowledgePdf.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(uploadKnowledgePdf.fulfilled, (state, { payload }) => {
      return { ...state, payload, loadingStatus: LoadingStatus.idle };
    });
    builder.addCase(uploadKnowledgePdf.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...chatsSlice.actions,
};
export const { reducer } = chatsSlice;
