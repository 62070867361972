import { css } from 'styled-components';

export const lightTheme = {
  name: 'light',
  colors: {
    white: '#FFFFFF',
    text: '#424565',
    textLight: '#888CAB',
    textExtraLight: '#9198AE',
    accent: '#0B0D1E',
    lightBckg: 'rgba(226, 229, 244, 0.5)',
    bckg: '#f0f2f9',
    border: '#888CAB',
    lightBorder: '#CFD7E2',
    extraLightBorder: '#BDC1CF',
    purple: '#5868F5',
    lightPurple: '#CCDDFF',
    linear: 'linear-gradient(277.62deg, #5D6BE9 -3.88%, #55B8FF 67.51%, #32E0D6 138.9%);',
    lightBlue: '#6DC4FF',
    blue: '#2D94F2',
    cian: '#4FEEE4',
    red: '#FF7799',
    lightRed: '#FFF1F5',
    disabled: '#A6A8BE',
  },
  typography: {
    h1: css`
      font-weight: 600;
      font-size: 36px;
      line-height: 60px;
      letter-spacing: -0.03em;
      color: ${({ theme: { colors } }) => colors.accent};

      @media (max-width: 765px) {
        font-size: 24px;
        line-height: 40px;
      }
    `,
    h3: css`
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.01em;
      color: ${({ theme: { colors } }) => colors.text};
    `,
    h4: css`
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: ${({ theme: { colors } }) => colors.text};
    `,
    text: css`
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: ${({ theme: { colors } }) => colors.text};

      @media (max-width: 765px) {
        font-size: 14px;
        line-height: 20px;
      }
    `,
    largeText: css`
      font-size: 20px;
      line-height: 180%;
      letter-spacing: 0.01em;
      color: ${({ theme: { colors } }) => colors.accent};

      @media (max-width: 765px) {
        font-size: 18px;
      }
    `,
    smallText: css`
      font-size: 14px;
      line-height: 22px;
      color: ${({ theme: { colors } }) => colors.accent};

      @media (max-width: 765px) {
        font-size: 12px;
        line-height: 20px;
      }
    `,
    superSmallText: css`
      font-size: 12px;
      line-height: 18px;
      color: ${({ theme: { colors } }) => colors.accent};

      @media (max-width: 765px) {
        font-size: 10px;
        line-height: 14px;
      }
    `,

    link: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme: { colors } }) => colors.purple};

      @media (max-width: 765px) {
        font-size: 14px;
        line-height: 20px;
      }
    `,
  },
};
