import { createAsyncThunk } from '@reduxjs/toolkit';
import { DiarizedResponseDto, DiarizeRequestDto, TranslationRequestDto, TranslationResponseDto } from 'api/analyze.api';
import { ExtraParamsThunkType } from 'store/types';
import { RequestErrors } from 'types';

export const getDiarizedFileAsync = createAsyncThunk<
  DiarizedResponseDto,
  DiarizeRequestDto,
  ExtraParamsThunkType<RequestErrors>
>('analyze/diarization', async (requestData, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.AnalyzeApi.requestDiarization(requestData);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const getTranslationAsync = createAsyncThunk<
  TranslationResponseDto,
  TranslationRequestDto,
  ExtraParamsThunkType<RequestErrors>
>('analyze/translate', async (requestData: TranslationRequestDto, { extra: { api }, rejectWithValue }) => {
  try {
    const { data } = await api.AnalyzeApi.getTranslation(requestData);
    return data.data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
