import { useEffect, useState } from 'react';

export default function useTimer() {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const toggleStopStart = () => {
    setIsActive((prev) => !prev);
  };

  const reset = () => {
    setSeconds(0);
    setIsActive(false);
  };

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | null | undefined = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) {
        return clearInterval(interval);
      }
    };
  }, [isActive, seconds]);

  const formatedTime = new Date(seconds * 1000).toISOString().slice(11, 19);

  return { time: formatedTime, toggleStopStart, reset };
}
