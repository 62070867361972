import { AIMessage, Spinner, UserMessage } from 'components/atoms';
import { IconSend } from 'components/atoms/svg';
import { actions as authAction } from 'features/auth';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { SSE } from 'sse.js';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { notifyErr } from 'utils/notification';

interface Conversation {
  role: string;
  content: string;
}

interface ChatInputProps {
  rag: boolean;
  sessionId: string;
  model: string;
  useHyde: boolean;
}

const ChatInput: FC<ChatInputProps> = ({ rag, sessionId, model, useHyde }) => {
  const { t } = useTranslation(['gpt', 'auth']);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const [userInput, setUserInput] = useState('');
  const dispatch = useAppDispatch();

  const [conversationContext, setConversationContext] = useState<Array<Conversation>>([]);
  const [response, setResponse] = useState('');
  const responseRef = useRef('');
  const [disabled, setDisabled] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    const handleStorage = () => {
      if (localStorage.getItem('persist:auth')) {
        const Authorized = JSON.parse(localStorage.getItem('persist:auth')!).isAuthorized;
        if (Authorized === 'false') {
          notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
          dispatch(authAction.signOut());
        }
      } else {
        notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
        dispatch(authAction.signOut());
      }
    };
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  useEffect(() => {
    if (userInput.length) {
      submitQuestionStream();
    }
  }, [conversationContext]);

  useEffect(() => {
    response.length &&
      setConversationContext((prevConversationContext) => {
        const lastMessage = prevConversationContext[prevConversationContext.length - 1];

        if (lastMessage && lastMessage.role === 'assistant') {
          // Update the last message from the assistant with the new content
          return prevConversationContext.map((item, index) =>
            index === prevConversationContext.length - 1 ? { role: 'assistant', content: response } : item,
          );
        } else {
          // If the last message is not from the server, add a new server message
          return [...prevConversationContext, { role: 'assistant', content: response }];
        }
      });
  }, [response]);

  const renderConversation = () => {
    const filteredConvo = conversationContext.filter((item) => item.role !== 'system' && item.content !== '');
    return filteredConvo;
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const scrollToBottom = () => {
    virtuosoRef.current?.scrollToIndex({
      index: renderConversation().length - 1,
    });
  };

  const submitQuestionStream = async () => {
    const API_URL = process.env.REACT_APP_GPT_API_DOMAIN + '/stream';
    responseRef.current = '';
    setResponse('');
    setUserInput('');
    setIsWaiting(true);

    const config = {
      prompt: userInput,
      rag: rag,
      session_id: sessionId,
      model: model,
      use_hyde: useHyde,
    };

    let key = '';
    if (localStorage.getItem('persist:auth') != null) {
      key = JSON.parse(localStorage.getItem('persist:auth')!).accessToken;
      key = 'Bearer ' + key.replace(/['"]+/g, '');
    } else {
      notifyErr(t('loginTimeout', { ns: 'auth' }), '10');
      dispatch(authAction.signOut());
    }

    const source = new SSE(API_URL, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: key,
      },
      payload: JSON.stringify(config),
    });
    source.onopen = (e) => console.log('Connection opened');
    source.onerror = (e) => {
      setIsWaiting(false);
      console.error('Error:', e);
      notifyErr(t('errorBotAnswer'), '7');
    };
    source.onmessage = (e) => {
      if (e.data !== '[DONE]') {
        setIsWaiting(false);
        responseRef.current = responseRef.current + e.data;
        setResponse(responseRef.current);
      } else {
        setIsWaiting(false);
        source.close();
        scrollToBottom();
        console.log(responseRef.current.length);
        if (responseRef.current.length < 2) {
          setResponse('Something went wrong');
        }
      }
    };
    source.stream();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setConversationContext([...conversationContext, { role: 'user', content: userInput }]);
  };

  return (
    <ContentWrapper>
      {isWaiting ? (
        <ChatContent>
          <Absolute>
            <Center>
              <Spinner />
            </Center>
          </Absolute>
        </ChatContent>
      ) : (
        <ChatContent>
          <MessageListWrapper>
            <Virtuoso
              ref={virtuosoRef}
              data={renderConversation()}
              initialTopMostItemIndex={renderConversation().length - 1}
              itemContent={(index, message) => {
                if (message.role == 'assistant') {
                  return (
                    <>
                      <AIMessage text={message.content} key={index} messageKey={index} />
                    </>
                  );
                } else if (message.role == 'user') {
                  return <UserMessage text={message.content} key={index} />;
                }
              }}
            />
          </MessageListWrapper>
        </ChatContent>
      )}
      <div style={{ position: 'absolute', bottom: 0, width: '98%', marginTop: '10px' }}>
        <MessageInputWrapper htmlFor="chat-input" data-cy="chat-input">
          <MessageInput
            id="chat-input"
            disabled={disabled}
            value={userInput}
            placeholder={t('messageInputPlaceholder') || ''}
            onChange={(event) => setUserInput(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleSubmit(event);
              }
            }}
          />
        </MessageInputWrapper>
        <SendMessageButton $disabled={disabled} onClick={handleSubmit} data-cy="submit">
          <IconSend />
        </SendMessageButton>
      </div>
    </ContentWrapper>
  );
};

const MessageListWrapper = styled.div`
  flex: 1 0 auto;
  margin-bottom: 10px;
`;

const MessageInputWrapper = styled.label`
  display: flex;

  padding: 12px 24px;

  gap: 12px;

  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 24px;

  user-select: none;
`;

const Absolute = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(226, 229, 244, 0.2);
`;

const Center = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageInput = styled.input`
  flex: 1;

  background: none;
  border: none;

  ${({ theme: { typography } }) => typography.text};
`;

const SendMessageButton = styled.button<{ $disabled: boolean }>`
  position: absolute;
  top: 12px;
  right: 24px;
  display: flex;
  padding: 0;
  color: ${({ theme: { colors } }) => colors.blue};

  opacity: ${({ $disabled }) => ($disabled ? '0.4' : '1')};
  cursor: ${({ $disabled }) => ($disabled ? 'auto' : 'pointer')};
`;

const ContentWrapper = styled.div`
  position: relative;
  border-radius: 12px;
  height: calc(100vh - 300px);
  margin: 0;
  width: 100%;
  overflow: hidden;
  padding: 1px;
  @media (max-width: 765px) {
    height: 100%;
    margin: 0;
    padding: 4px;
  }
`;

const ChatContent = styled.div`
  display: flex;
  grid-template-columns: 1fr;
  grid-template-areas: '.';
  min-height: calc(100vh - 380px);

  @media (max-width: 1160px) {
    grid-template-columns: minmax(200px, 200px) 1fr minmax(200px, 200px);
  }

  @media (max-width: 765px) {
    display: flex;
    grid-template-columns: 1fr;
    grid-template-areas: '.';
    padding-bottom: 18px;
  }
`;

export default ChatInput;
