import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={22} fill="#5868F5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.893.893A2.974 2.974 0 0 1 5 0h8.5a1 1 0 0 1 .707.293l5.5 5.5A1 1 0 0 1 20 6.5V19c0 .774-.311 1.526-.893 2.107A2.973 2.973 0 0 1 17 22h-.5a1 1 0 1 1 0-2h.5a.976.976 0 0 0 .693-.307A.976.976 0 0 0 18 19V6.914L13.086 2H5a.976.976 0 0 0-.693.307A.976.976 0 0 0 4 3v3a1 1 0 0 1-2 0V3c0-.774.311-1.526.893-2.107Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 0a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1ZM.879 15.879A3 3 0 0 1 6 18v1a3 3 0 1 1-6 0v-1a3 3 0 0 1 .879-2.121ZM3 17a1 1 0 0 0-1 1v1a1 1 0 1 0 2 0v-1a1 1 0 0 0-1-1Zm8 0a1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-1a1 1 0 0 0-1-1Zm-2.121-1.121A3 3 0 0 1 14 18v1a3 3 0 0 1-6 0v-1a3 3 0 0 1 .879-2.121Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10a5 5 0 0 0-5 5v3a1 1 0 1 1-2 0v-3a7 7 0 1 1 14 0v3a1 1 0 1 1-2 0v-3a5 5 0 0 0-5-5Z"
    />
  </svg>
);

export default SvgComponent;
