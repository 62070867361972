import { createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../types';
import { requestVerificationTokenAsync, signInAsync, signUpAsync, verifyTokenAsync } from './thunks';
import { AuthStore } from './types';

const initialState: AuthStore = {
  userId: undefined,
  email: undefined,
  name: undefined,
  accessToken: undefined,
  isAuthorized: false,
  loadingStatus: LoadingStatus.idle,
  error: null,
  isVerified: false,
  verificationToken: undefined,
  verificationTokenIsVerified: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.accessToken = action.payload;
      state.isAuthorized = true;
    },
    signUp: (state, action) => {
      state.accessToken = action.payload;
      state.isAuthorized = true;
    },
    signOut: () => initialState,
    setVerificationToken: (state, action) => {
      state.verificationToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(signInAsync.fulfilled, (state, { payload }) => {
      return { ...state, ...payload.data, loadingStatus: LoadingStatus.idle, isAuthorized: true };
    });
    builder.addCase(signInAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });

    builder.addCase(signUpAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(signUpAsync.fulfilled, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
    builder.addCase(signUpAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });

    builder.addCase(verifyTokenAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(verifyTokenAsync.fulfilled, (state) => {
      state.loadingStatus = LoadingStatus.idle;
      state.verificationTokenIsVerified = true;
    });
    builder.addCase(verifyTokenAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });

    builder.addCase(requestVerificationTokenAsync.pending, (state) => {
      if (state.loadingStatus === LoadingStatus.idle) {
        state.loadingStatus = LoadingStatus.pending;
      }
    });
    builder.addCase(requestVerificationTokenAsync.fulfilled, (state, { payload }) => {
      state.loadingStatus = LoadingStatus.idle;
      state.verificationToken = payload.data;
    });
    builder.addCase(requestVerificationTokenAsync.rejected, (state) => {
      state.loadingStatus = LoadingStatus.idle;
    });
  },
});

export const actions = {
  ...authSlice.actions,
};
export const { reducer } = authSlice;
