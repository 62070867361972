import { FC } from 'react';
import styled from 'styled-components';

interface UserMessageProps {
  text: string;
}

const UserMessage: FC<UserMessageProps> = ({ text }) => {
  return <Root>{text}</Root>;
};

const Root = styled.div`
  margin-left: auto;
  margin-bottom: 8px;

  max-width: 70%;
  width: max-content;

  padding: 16px;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 12px 2px 12px 12px;
  ${({ theme: { typography } }) => typography.text};
`;

export default UserMessage;
