import { SVGProps } from 'react';

const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.3438 16.4336C17.6602 16.7852 17.6602 17.3125 17.3438 17.6289C16.9922 17.9805 16.4648 17.9805 16.1484 17.6289L12 13.4453L7.81641 17.6289C7.46484 17.9805 6.9375 17.9805 6.62109 17.6289C6.26953 17.3125 6.26953 16.7852 6.62109 16.4336L10.8047 12.25L6.62109 8.06641C6.26953 7.71484 6.26953 7.1875 6.62109 6.87109C6.9375 6.51953 7.46484 6.51953 7.78125 6.87109L12 11.0898L16.1836 6.90625C16.5 6.55469 17.0273 6.55469 17.3438 6.90625C17.6953 7.22266 17.6953 7.75 17.3438 8.10156L13.1602 12.25L17.3438 16.4336Z"
      fill="currentColor"
    />
  </svg>
);

export default IconClose;
