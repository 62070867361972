import { Button, Spinner } from 'components/atoms';
import { AddIcon } from 'components/atoms/svg';
import { gaMeasurementId } from 'config';
import { selectSpeakersList } from 'features/speakers/selectors';
import { getAllSpeakersAsync } from 'features/speakers/thunks';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';

import { AuthorizedRoutes } from '../../constants/routes';
import { SpeakerItem } from './components';

ReactGA.initialize(gaMeasurementId);

const SpeakersListPage = () => {
  const { t } = useTranslation('sid');
  ReactGA.send({ hitType: 'pageview', page: '/sid/speakers', title: 'SID Demo' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const speakers = useSelector(selectSpeakersList);

  useEffect(() => {
    dispatch(getAllSpeakersAsync());
  }, []);
  return (
    <Root>
      <SpeakersListWrapper>
        <SpeakersListHeader>
          <SpeakerListTitle>{t('speakers')}</SpeakerListTitle>
          <Button
            text={t('addNew')}
            onClick={() => navigate(AuthorizedRoutes.REAL_SID_NEW_SPEAKER)}
            variant="button"
            icon={<AddIcon />}
          />
        </SpeakersListHeader>
        <SpeakersList>
          {speakers ? (
            <SpeakerItemWrapper>
              {[...speakers].reverse().map((speaker, index) => (
                <SpeakerItem key={index} name={speaker.name}></SpeakerItem>
              ))}
            </SpeakerItemWrapper>
          ) : (
            <SpeakersListEmpty>
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            </SpeakersListEmpty>
          )}
        </SpeakersList>
      </SpeakersListWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  min-height: 100%;
  max-height: 100%;
  grid-auto-rows: minmax(0%, 100%);
  padding-bottom: 55px;
  gap: 12px;

  grid-template-areas: 'speakers';

  @media (max-width: 765px) {
    padding-bottom: 10px;
  }

  @media (max-height: 850px) {
    padding-bottom: 10px;
  }
`;

const SpeakersListWrapper = styled.div`
  grid-area: speakers;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 12px;
  padding: 24px;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  @media screen and (max-width: 750px) and (orientation: portrait) {
    height: calc(100vh - 165px);
  }
`;

const SpeakersList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const SpeakersListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SpeakerListTitle = styled.div`
  ${({ theme: { typography } }) => typography.h3}
`;

const SpeakersListEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SpinnerWrapper = styled.div`
  overflow: hidden;
`;

const SpeakerItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default SpeakersListPage;
