import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} fill="#5868F5" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.64 6.64h-5v5H5v-5H0V5h5V0h1.64v5h5v1.64Z" />
  </svg>
);

export default SvgComponent;
