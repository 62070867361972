import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="#F79" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.86 9.017a9.978 9.978 0 0 1-19.855 2.01.936.936 0 0 1 1.861-.197 8.107 8.107 0 1 0 12.427-7.683V5.3a.935.935 0 1 1-1.87 0V0h5.3a.935.935 0 1 1 0 1.87h-1.978a9.979 9.979 0 0 1 4.115 7.147ZM8.68 2.494A1.247 1.247 0 1 0 8.68 0a1.247 1.247 0 0 0 0 2.494Zm-2.805.312a1.247 1.247 0 1 1-2.495 0 1.247 1.247 0 0 1 2.495 0ZM1.82 7.483a1.247 1.247 0 1 0 0-2.494 1.247 1.247 0 0 0 0 2.494Z"
    />
  </svg>
);

export default SvgComponent;
