import { createSelector } from 'reselect';
import { RootState } from 'store/types';

import { SpeakersStore } from './types';

export const selectSpeakers = (state: RootState): SpeakersStore => state.speakers;
export const selectLoadingStatus = createSelector(selectSpeakers, (speakers) => speakers.loadingStatus);
export const selectSpeakersList = createSelector(selectSpeakers, (speakers) => speakers.speakers);
export const selectVerificationSpeaker = createSelector(selectSpeakers, (speakers) => speakers.verificationSpeaker);
export const selectVerificationSpeakerModel = createSelector(
  selectSpeakers,
  (speakers) => speakers.verificationSpeakerModel,
);
