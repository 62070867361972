import { FC } from 'react';

const IconSend: FC<React.DetailedHTMLProps<React.HTMLAttributes<SVGSVGElement>, SVGSVGElement>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2_843)">
        <path
          d="M11 12L5.72054 12.0482"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.2678 11.518L2.50037 21.3558L5.72051 12.0482L1.8737 2.98168L21.2678 11.518Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_843">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSend;
