import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="#888CAB" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16 32L0 16L16 0L18.85 2.8L7.65 14H32V18H7.65L18.85 29.2L16 32Z" />
  </svg>
);

export default SvgComponent;
