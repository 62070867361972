import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ShopItem } from 'viewModels/ShopVM';

interface ShopItemCardProps {
  items: ShopItem[];
  index: number;
  ShopIdx: number;
}
const ShopItemCard = (props: ShopItemCardProps) => {
  const { t } = useTranslation('gpt');
  const { items, index, ShopIdx } = props;

  return (
    <>
      {!!items &&
        items.map((item: ShopItem, i: number) => {
          if (index === ShopIdx) {
            return (
              <Root key={i}>
                <Title dangerouslySetInnerHTML={{ __html: item.title }} />
                <Image src={item.image} />
                <PriceTag>최저 {new Intl.NumberFormat('ko-KR').format(item.lprice)}원</PriceTag>
                <BuyBtn href={item.link} target={'_blank'}>
                  {t('purchase')}
                </BuyBtn>
              </Root>
            );
          }
        })}
    </>
  );
};

const Root = styled.div`
  width: 144px;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 12px 12px;
  box-sizing: border-box;
  margin: 0 8px 0 0;
  background-color: #ffffff;
`;
const Image = styled.img`
  width: 120px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
`;
const Title = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  font-family: 'Noto Sans KR';
  line-height: 20px;
`;
const BuyBtn = styled.a`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 60px;
  height: 30px;
  background-color: #03c75a;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Noto Sans KR';
  text-decoration: none;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
`;
const PriceTag = styled.div`
  align-self: flex-end;
  font-size: 12px;
  font-weight: 700;
  margin: 12px 0;
`;
export default ShopItemCard;
