import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export type ButtonVariant = 'submit' | 'button';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  link?: string;
  linkFilename?: string;
  downloadFileType?: string;
  icon?: ReactNode;
  isFullWidth?: boolean;
  variant?: ButtonVariant;
}

const Button: FC<ButtonProps> = ({
  text,
  icon,
  isFullWidth = false,
  link,
  linkFilename,
  variant = 'submit',
  downloadFileType,
  ...rest
}) => {
  return (
    <Root $isFullWidth={isFullWidth} $variant={variant} {...rest}>
      {icon && <Icon>{icon}</Icon>}
      {link ? (
        <a download={linkFilename} href={link} type={downloadFileType}>
          {text}
        </a>
      ) : (
        text
      )}
    </Root>
  );
};

const Root = styled.button<{ $isFullWidth: boolean; $variant: ButtonVariant }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, $variant }) => ($variant === 'submit' ? theme.colors.purple : theme.colors.lightBckg)};
  padding: 16px 24px;
  border-radius: ${({ $variant }) => ($variant === 'submit' ? '8px' : '24px')};
  height: max-content;
  white-space: nowrap;

  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  color: ${({ theme, $variant }) => ($variant === 'submit' ? theme.colors.white : theme.colors.text)};

  & a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  ${({ $isFullWidth }) =>
    $isFullWidth
      ? css`
          width: 100%;
        `
      : ''}

  &:hover {
    ${({ $variant, theme }) =>
      $variant === 'submit'
        ? css`
            opacity: 0.9;
          `
        : css`
            color: ${theme.colors.purple};
            & a {
              color: ${theme.colors.purple};
            }
          `}

    cursor: pointer;
  }

  &:disabled {
    ${({ $variant, theme }) =>
      $variant === 'submit'
        ? css`
            opacity: 0.3;
          `
        : css`
            color: ${theme.colors.disabled};
            & a {
              color: ${theme.colors.disabled};
            }
            & div > svg {
              fill: ${theme.colors.disabled};
            }
          `}

    cursor: not-allowed;
  }

  @media (max-width: 765px) {
    font-size: 15px;
    line-height: 20px;
    min-height: 54px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
`;

export default Button;
