import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={20} fill="#888CAB" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M15 7.5h-1.25V5c0-2.773-2.266-5-5-5-2.773 0-5 2.266-5 5v2.5H2.5A2.492 2.492 0 0 0 0 10v7.5C0 18.867 1.094 20 2.5 20H15c1.367 0 2.5-1.133 2.5-2.5V10c0-1.367-1.133-2.5-2.5-2.5ZM5.625 5A3.11 3.11 0 0 1 8.75 1.875 3.134 3.134 0 0 1 11.875 5v2.5h-6.25V5Zm10 12.5a.642.642 0 0 1-.625.625H2.5a.617.617 0 0 1-.625-.625V10c0-.313.273-.625.625-.625H15c.313 0 .625.313.625.625v7.5Z" />
  </svg>
);

export default SvgComponent;
