import { unwrapResult } from '@reduxjs/toolkit';
import { deepgramWsJaString, deepgramWsKoString, deepgramWsRuString, deepgramWsZhTWString } from 'config';
import { actions as analyzeAction } from 'features/analyze';
import { getTranslationAsync } from 'features/analyze/thunks';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/reducers';

import useMediaRecorder from './useMediaRecorder';

export type Words = Array<{
  confidence: number;
  end: number;
  punctuated_word: string;
  speaker: number;
  start: number;
  word: string;
}>;

export default function useStreamSTT() {
  const socket = useRef<WebSocket>();
  const mediaRecorder = useRef<MediaRecorder>();
  const [socketCount, setSocketCount] = useState(0);
  const [currentLanguage, setCurrentRTLanguage] = useState('ko');
  const [targetLanguage, setTargetRTLanguage] = useState('ko');

  const dispatch = useAppDispatch();
  const {
    mediaStream,
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
  } = useMediaRecorder();

  useEffect(() => {
    if (mediaStream && mediaStream.active) {
      switch (currentLanguage) {
        case 'zh-TW':
          socket.current = new WebSocket(deepgramWsZhTWString);
          break;
        case 'ja':
          socket.current = new WebSocket(deepgramWsJaString);
          break;
        case 'ru':
          socket.current = new WebSocket(deepgramWsRuString);
          break;
        default:
          socket.current = new WebSocket(deepgramWsKoString);
      }

      mediaRecorder.current = new MediaRecorder(mediaStream);
      mediaRecorder.current.start();
    }
  }, [mediaStream]);

  useEffect(() => {
    if (mediaStream && mediaStream.active) {
      const interval = setInterval(() => {
        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.requestData();
        }
      }, 1000);

      socket.current!.onopen = () => {
        console.log('connected');
      };

      socket.current!.onclose = () => {
        console.log('closed');
        if (mediaRecorder.current!.state === 'paused') {
          console.log('new socket');
          if (currentLanguage == 'ko') {
            socket.current = new WebSocket(deepgramWsKoString);
          } else {
            socket.current = new WebSocket(deepgramWsJaString);
          }
          setSocketCount((prev) => ++prev);
        }

        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.stop();
          clearInterval(interval);
        }
      };

      socket.current!.onerror = (error) => {
        console.log('ws error: ', error);

        if (mediaRecorder.current!.state !== 'inactive') {
          mediaRecorder.current!.stop();
        }

        clearInterval(interval);
      };

      socket.current!.onmessage = (data) => {
        const parsedData = JSON.parse(data.data);
        if (parsedData.hasOwnProperty('channel')) {
          console.log(parsedData.channel.alternatives[0]);
          if (parsedData.is_final == true && parsedData.channel.alternatives[0].transcript.length > 0) {
            if (currentLanguage !== targetLanguage) {
              dispatch(
                getTranslationAsync({
                  originalText: [parsedData.channel.alternatives[0].transcript],
                  sourceLanguage: currentLanguage,
                  targetLanguage: targetLanguage,
                }),
              )
                .then(unwrapResult)
                .then((data) => {
                  console.log(data);
                  const word = {
                    speaker: 0,
                    word: data.translation,
                    start: 0,
                  };
                  dispatch(analyzeAction.setRealtimeStt([word]));
                });
            } else {
              const word = {
                speaker: 0,
                word: parsedData.channel.alternatives[0].transcript,
                start: 0,
              };
              dispatch(analyzeAction.setRealtimeStt([word]));
            }
          }
        }
      };

      mediaRecorder.current!.ondataavailable = (e: BlobEvent) => {
        setTimeout(() => {
          if (e.data.size !== 0) {
            const blob = e.data;
            console.log(blob);
            socket.current!.send(blob);
          }
        }, 500);
      };
    }
  }, [mediaStream, socketCount]);

  return {
    startRecording,
    mediaBlobUrl,
    clearBlobUrl,
    stopRecording,
    pauseRecording,
    resumeRecording,
    savedMediaRecorder: mediaRecorder.current,
    status,
    setCurrentRTLanguage,
    setTargetRTLanguage,
  };
}
