import Chart, { ChartArea } from 'chart.js/auto';
import { Button, InputButton, MicrophoneAudioVisualizer, RecordStopButton } from 'components/atoms';
import { BackArrowIcon, Microphone, ResetIcon, UploadIcon } from 'components/atoms/svg';
import { Playback } from 'components/molecules';
import { gaMeasurementId } from 'config';
import { AuthorizedRoutes } from 'constants/routes';
import { actions as speakersAction } from 'features/speakers';
import { selectVerificationSpeaker } from 'features/speakers/selectors';
import { getSpeakerModelAsync } from 'features/speakers/thunks';
import useMediaStream from 'hooks/useMediaStream';
import useTimer from 'hooks/useTimer';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';

import { ChartLabel } from './components';
import usePhonexiaVoiceprint from './hooks/usePhonexiaVoiceprint';

function getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
  let width, height, gradient;

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#ED2323');
    gradient.addColorStop(0.5, '#f3e704');
    gradient.addColorStop(1, '#46AE15');
  }

  return gradient;
}

ReactGA.initialize(gaMeasurementId);

const VoiceVerificationPage = () => {
  const { t } = useTranslation('sid');
  ReactGA.send({ hitType: 'pageview', page: '/sid/verification', title: 'SID Demo' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [phonexiaIsAvailable, setPhonexiaIsAvailable] = useState(false);

  const { handleStartRecording, handleStopRecording, isCanStart } = usePhonexiaVoiceprint({
    onGetScore: addDataToChardHandler,
    setPhonexiaIsAvailable,
  });

  const [isVerificationStarted, setIsVerificationStarted] = useState(false);
  const [lastChartData, setLastChartData] = useState<number | undefined>();
  const [isRecordingStoped, setIsRecordingStoped] = useState(false);
  const [blob, setBlob] = useState<Blob>();

  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart<'line', number[], number>>();
  const mediaStreamSourceRef = useRef<MediaStreamAudioSourceNode>();
  const audioWorkletNodeRef = useRef<AudioWorkletNode>();
  const mediaStreamRef = useRef<MediaStream>();

  const verificationSpeaker = useSelector(selectVerificationSpeaker);

  const { toggleStopStart, time } = useTimer();

  const { width } = useWindowDimensions();

  function addDataToChardHandler(startDate: Date, score?: number) {
    const endDate = new Date();
    const diff = startDate.getTime() - endDate.getTime();
    const diffSeconds = Math.floor(Math.abs(diff / 1000));

    let scoreValue = 50;
    if (score !== undefined) {
      scoreValue = score;
    }
    if (canvasRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!chartRef.current?.data.labels?.includes(`${diffSeconds}s` as any)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        chartRef.current?.data.labels?.push(`${diffSeconds}s` as any);
        setLastChartData(Number(scoreValue));
        chartRef.current?.data.datasets.forEach((dataset) => {
          dataset.data.push(scoreValue);
        });
      }

      chartRef.current?.update();
    }
  }

  const { startRecording, mediaBlobUrl, stopRecording, clearBlobUrl, stopMediaRecording } = useMediaStream();

  const startRecordingHandler = () => {
    if (isCanStart) {
      handleStartRecording();
      startRecording();
      setIsVerificationStarted(true);
      toggleStopStart();
      clearBlobUrl();
    }
  };

  const stopRecordingHandler = async () => {
    await handleStopRecording();
    stopRecording();
    stopMediaRecording();
    toggleStopStart();
    setIsRecordingStoped(true);
    mediaStreamSourceRef.current?.disconnect();
    audioWorkletNodeRef.current?.disconnect();

    mediaStreamRef.current?.getTracks().forEach(function (track) {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  };

  const resetHandler = async () => {
    // will reset phonexia stream and read speaker form local storage
    window.location.reload();
  };

  const fetchBlob = async (
    url: string,
    type: string,
    name: string,
    options?: {
      fileIsUpload: boolean;
    },
  ) => {
    if (options?.fileIsUpload) {
      setIsVerificationStarted(true);
    }

    const response = await fetch(url);
    const responseBlob = await response.blob();

    setBlob(responseBlob);

    const file = new File([responseBlob], name, {
      type: type ? type : 'audio/wav',
    });

    const formData = new FormData();

    formData.append('file', file, name);
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files[0]) {
      const blobLink = URL.createObjectURL(files[0]);
      fetchBlob(blobLink, files[0].type, files[0].name, { fileIsUpload: true });
      setIsFileUploaded(true);
      setIsRecordingStoped(true);
    }
  };

  useEffect(() => {
    if (mediaBlobUrl) fetchBlob(mediaBlobUrl, 'audio/wav', 'recorded_audio');
  }, [mediaBlobUrl]);

  useEffect(() => {
    const asyncHandleStopRecording = async () => {
      await handleStopRecording();
      mediaStreamSourceRef.current?.disconnect();
      audioWorkletNodeRef.current?.disconnect();

      mediaStreamRef.current?.getTracks().forEach(function (track) {
        if (track.readyState == 'live') {
          track.stop();
        }
      });
    };

    return () => {
      dispatch(speakersAction.unsetVerificationSpeaker());
      asyncHandleStopRecording();
    };
  }, []);

  useEffect(() => {
    if (!verificationSpeaker) {
      const speaker = localStorage.getItem('verification-speaker');
      if (speaker) {
        dispatch(getSpeakerModelAsync(speaker));
      }
    } else {
      dispatch(getSpeakerModelAsync(verificationSpeaker!));
    }
  }, []);

  useEffect(() => {
    if (canvasRef.current) {
      const chart = new Chart(canvasRef.current, {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: width < 485,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            filler: {
              propagate: false,
            },
          },
          scales: {
            x: {
              display: true,
            },
            y: {
              display: true,
              min: -5,
              max: 105,
              // position: 'right',
              ticks: {
                crossAlign: 'near',
                backdropColor: '#CFD7E2',
                color: '',
                count: 4,
                callback: function () {
                  return '';
                },
              },
            },
          },
          elements: {
            line: {
              tension: 0.5,
            },
          },
        },
        data: {
          labels: ['', '0s'],
          datasets: [
            {
              label: '',
              data: [50, 50],
              borderColor: function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  // This case happens on initial chart load
                  return;
                }
                return getGradient(ctx, chartArea);
              },
              pointStyle: false,
            },
          ],
        },
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current = chart;
    }
  }, [isVerificationStarted]);

  return (
    <Root>
      <Header>
        <StyledBackArrowIcon onClick={() => navigate(AuthorizedRoutes.REAL_SID)} />
        <Title>{t('verification')}</Title>
      </Header>

      {!isVerificationStarted ? (
        <VerificationBlock>
          <StartActionWrapper>
            {phonexiaIsAvailable ? (
              <>
                <StartContentTitle>{t('startRecording')}</StartContentTitle>
                <RecordActionButton onClick={startRecordingHandler}>
                  <Microphone />
                </RecordActionButton>
                <Text $mb={16} style={{ display: 'none' }}>
                  {t('orUploadFile')}
                </Text>
                <InputButton
                  style={{ display: 'none' }}
                  text={t('uploadAudioFile')}
                  icon={<UploadIcon />}
                  accept=".mp3, .wav, .flac"
                  onChange={onLoadFile}
                />
              </>
            ) : (
              <StartContentTitle>{t('serviceUnavailable')}</StartContentTitle>
            )}
          </StartActionWrapper>
        </VerificationBlock>
      ) : (
        <VerificationBlock>
          <Content>
            <ContentTitle>{verificationSpeaker}</ContentTitle>
            <ChartWrapper>
              <CanvasWrapper>
                <canvas ref={canvasRef} />
              </CanvasWrapper>
              <ChartLabels>
                <ChartLabel
                  text={t('verified').toUpperCase()}
                  highlightColor="#46AE15"
                  isHighlight={!!lastChartData && lastChartData >= 60}
                />
                <ChartLabel
                  text={t('notSure').toUpperCase()}
                  highlightColor="#f3e704"
                  isHighlight={!!lastChartData && lastChartData < 60 && lastChartData >= 40}
                />
                <ChartLabel
                  text={t('someoneElse').toUpperCase()}
                  highlightColor="#ED2323"
                  isHighlight={!!lastChartData && lastChartData < 40}
                />
              </ChartLabels>
            </ChartWrapper>
            <Center>
              {!isFileUploaded && <Timer>{time}</Timer>}
              {!isRecordingStoped && <RecordStopButton stopAction={stopRecordingHandler} />}
              {blob || isFileUploaded ? <Playback blob={blob} /> : <StyledMicrophoneAudioVisualizer />}
              {isRecordingStoped && (
                <Button
                  text={t('reset')}
                  icon={<ResetIcon />}
                  disabled={false}
                  variant="button"
                  onClick={resetHandler}
                  isFullWidth
                />
              )}
            </Center>
          </Content>
        </VerificationBlock>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  min-height: 100%;
  max-height: 100%;
  grid-auto-rows: max-content minmax(0%, 100%);
  padding-bottom: 55px;

  grid-template-areas: 'header' 'verification';

  @media (max-width: 765px) {
    padding-bottom: 10px;
  }

  @media (max-height: 850px) {
    padding-bottom: 10px;
  }
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  ${({ theme: { typography } }) => typography.h1}
`;

const VerificationBlock = styled.div`
  grid-area: verification;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  @media screen and (max-width: 750px) and (orientation: portrait) {
    height: calc(100vh - 165px);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledBackArrowIcon = styled(BackArrowIcon)`
  margin-right: 24px;
  cursor: pointer;
`;

const ContentTitle = styled.div`
  ${({ theme: { typography } }) => typography.h3};
  display: flex;
  justify-content: center;
  padding-top: 38px;
  color: ${({ theme: { colors } }) => colors.accent};
  margin-bottom: 24px;

  @media (max-width: 765px) {
    padding-top: 8px;
  }

  @media (max-height: 765px) {
    padding-top: 8px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  height: 230px;
  margin-bottom: 64px;

  @media (max-width: 765px) {
    height: 150px;
    margin-bottom: 20px;
  }

  @media (max-height: 850px) {
    height: 150px;
    margin-bottom: 20px;
  }

  @media (max-width: 485px) {
    height: 100px;
  }
`;

const ChartLabels = styled.div`
  flex: 1 0 auto;
  display: flex;
  width: max-content;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 50px;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 765px) {
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const CanvasWrapper = styled.div`
  flex: 1 1 auto;
`;

const StartActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: -75px;
`;

const StartContentTitle = styled.div`
  ${({ theme: { typography } }) => typography.largeText};
  margin-bottom: 12px;
`;

const RecordActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 2px solid #6dc4ff;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 90px;

  @media (max-height: 850px) {
    margin-bottom: 22px;
  }
`;

const Text = styled.div<{ $mb?: number }>`
  ${({ theme: { typography } }) => typography.text};
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : 0)};
`;

const Timer = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  width: 102px;
  color: ${({ theme: { colors } }) => colors.accent};
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const StyledMicrophoneAudioVisualizer = styled(MicrophoneAudioVisualizer)``;

export default VoiceVerificationPage;
