import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

import { StopIcon } from '../svg';

interface RecordStopButtonProps {
  stopAction: () => void;
}

const RecordStopButton: FC<RecordStopButtonProps> = ({ stopAction }) => {
  return (
    <>
      <ButtonWrapper>
        <StopButton onClick={stopAction}>
          <StopIcon />
        </StopButton>
        <PulsedBlock />
        <PulsedBlock />
        <PulsedBlock />
      </ButtonWrapper>
    </>
  );
};

const pulse = keyframes`
  from {
    transform:scale(0.1);
  }

  to {
    transform:scale(1);
  }`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  & div:nth-child(2) {
    animation: ${pulse} 2s infinite;
    top: -8px;
    width: 52px;
    height: 52px;
    opacity: 0.5;
  }
  & div:nth-child(3) {
    animation: ${pulse} 2s infinite 0.3s;
    top: -16px;
    width: 68px;
    height: 68px;
    opacity: 0.4;
  }
  & div:nth-child(4) {
    animation: ${pulse} 2s infinite 0.6s;
    top: -24px;
    width: 84px;
    height: 84px;
    opacity: 0.3;
  }
`;

const StopButton = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  background: linear-gradient(277.62deg, #5d6be9 -3.88%, #55b8ff 67.51%, #32e0d6 138.9%);
`;

const PulsedBlock = styled.div`
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  background: ${({ theme: { colors } }) => colors.lightPurple};
`;

export default RecordStopButton;
