import emailjs from '@emailjs/browser';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from 'components/atoms';
import { EmailIcon } from 'components/atoms/svg';
import { InputField } from 'components/molecules';
import { emailJSPublicKey, emailJSServiceId, emailJSTemplateId, verificationTokenURL } from 'config';
import { UnauthorizedRoutes } from 'constants/routes';
import { selectLoadingStatus } from 'features/auth/selectors';
import { requestVerificationTokenAsync } from 'features/auth/thunks';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';
import { notifyErr } from 'utils/notification';
import { patterns } from 'utils/patterns';

interface FormValue {
  email: string;
}

const RequestVerificationLinkForm = () => {
  const { t } = useTranslation(['auth']);
  const dispatch = useAppDispatch();
  const loadingStatus = useSelector(selectLoadingStatus);
  const isLoading = loadingStatus === LoadingStatus.pending;
  const [emailIsSending, setEmailIsSending] = useState(false);
  const [dataIsSent, setDataIsSent] = useState(false);

  const { control, handleSubmit, watch } = useForm<FormValue>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const fieldEmailValue = watch('email');

  const onSubmit = async (data: FormValue) => {
    await dispatch(requestVerificationTokenAsync(data.email))
      .then(async (response) => {
        unwrapResult(response);
        setEmailIsSending(true);
        if (response.payload.data.token) {
          await emailjs.send(
            emailJSServiceId,
            emailJSTemplateId,
            {
              link: `${verificationTokenURL}/auth/verification?token=${response.payload.data.token}`,
              recipientEmail: fieldEmailValue,
              linkName: verificationTokenURL,
            },
            emailJSPublicKey,
          );
        }
      })
      .then(() => {
        setEmailIsSending(false);
        setDataIsSent(true);
      })
      .catch(() => {
        notifyErr(t('errorMessageVerificationLink'), '5');
      });
  };

  if (dataIsSent) {
    return (
      <Root>
        <LargeText>{t('verificationLinkSent')}</LargeText>
        <LinkWrapper>
          <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
        </LinkWrapper>
      </Root>
    );
  }

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Title>{t('requestEmailVerificationLink')}</Title>

      <FieldWrapper>
        <InputField
          control={control}
          name="email"
          rules={{
            required: { value: true, message: t('requiredField') },
            pattern: {
              value: patterns.email,
              message: t('invalidEmailAddressFormat'),
            },
          }}
          label={t('email')}
          icon={<EmailIcon />}
        />
      </FieldWrapper>

      <StyledButton text={t('confirm').toUpperCase()} disabled={isLoading || emailIsSending} />

      <LinkWrapper>
        <Text>{t('accountExistQuestion')}</Text>
        <StyledLink to={UnauthorizedRoutes.SIGN_IN}>{t('logInHere')}</StyledLink>
      </LinkWrapper>
      <RightsText>© 2024 DARWIN INTELLIGENCE. All rights reserved</RightsText>
    </Root>
  );
};

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 765px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  ${({ theme: { typography } }) => typography.h1}
`;

const FieldWrapper = styled.div<{ $mb?: number }>`
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : '16px')};
`;

const StyledButton = styled(Button)`
  margin-bottom: 24px;
`;

const Text = styled.span`
  ${({ theme }) => theme.typography.text};
  margin-right: 8px;
`;

const LargeText = styled.h2`
  ${({ theme: { typography } }) => typography.largeText}

  @media (max-width: 765px) {
    text-align: center;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 765px) {
    margin-bottom: 52px;
    justify-content: center;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const RightsText = styled.div`
  ${({ theme }) => theme.typography.smallText};
  margin-top: auto;

  @media (max-width: 765px) {
    text-align: center;
  }
`;

export default RequestVerificationLinkForm;
