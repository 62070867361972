import { unwrapResult } from '@reduxjs/toolkit';
import { InputButton, Spinner } from 'components/atoms';
import { Microphone, UploadIcon } from 'components/atoms/svg';
import { DetailedPlayback } from 'components/molecules';
import { gaMeasurementId } from 'config';
import { actions as analyzeAction } from 'features/analyze';
import {
  selectCurrentAudioFilename,
  selectDiarizedResult,
  selectDiarizedResultLoadingStatus,
  selectRealtimeStt,
} from 'features/analyze/selector';
import { getDiarizedFileAsync, getTranslationAsync } from 'features/analyze/thunks';
import useStreamSTT from 'hooks/useStreamSTT';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/reducers';
import styled from 'styled-components';
import { LoadingStatus } from 'types';
import { notifyErr, notifySuc } from 'utils/notification';
import WaveSurfer from 'wavesurfer.js';

import { SttContent } from './components';
import { groupBySpeaker, makeTextFile } from './helper';

export type RealtimeSTTData = { words: string[]; speaker: number; isBookmarked?: boolean; start?: number };

function stopMicrophone(stream: MediaStream) {
  stream.getTracks().forEach(function (track) {
    if (track.readyState == 'live' && track.kind === 'audio') {
      track.stop();
    }
  });
}

type SupportedLanguages = 'ko' | 'en-US' | 'ja' | 'zh' | 'ru' | 'ar';

ReactGA.initialize(gaMeasurementId);

const RealtimeSTT = () => {
  const { t } = useTranslation(['stt']);
  ReactGA.send({ hitType: 'pageview', page: '/stt', title: 'STT Demo' });
  const dispatch = useAppDispatch();
  const tags = ['realTimeVoiceToText', 'demoTranslate', 'noiseIsNoProblem'];
  const diarizedResult = useSelector(selectDiarizedResult);
  const diarizedResultIsLoading = useSelector(selectDiarizedResultLoadingStatus) === LoadingStatus.pending;
  const realtimeStt = useSelector(selectRealtimeStt);
  const currentAudioFilename = useSelector(selectCurrentAudioFilename);

  const [isRecording, setIsRecording] = useState(false);
  const [isPostProcessing, setIsPostProcessing] = useState(false);
  const [isAnalyzed, setIsAnalyzed] = useState(false);
  const [isDataRecorded, setIsDataRecorded] = useState(false);
  const [currentData, setCurrentData] = useState<RealtimeSTTData[]>([]);
  const [filename, setFilename] = useState(currentAudioFilename);
  const [fileIsUpload, setFileIsUpload] = useState(false);
  const [fileUploadError, setFileUploadError] = useState('');
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [downloadTranslationLink, setDownloadTranslationLink] = useState<string>('');
  const [downloadFileAudio, setDownloadFileAudio] = useState<string>('');
  const [currentPlayTime, setCurrentPlayTime] = useState<number>();
  const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguages>('ko');
  const [translateLanguage, setTranslateLanguage] = useState<SupportedLanguages>('ko');

  const [blob, setBlob] = useState<Blob>();

  const titleInputRef = useRef<HTMLInputElement>(null);
  const websufRef = useRef<WaveSurfer>();
  const mediaStreamRef = useRef<MediaStream>(null);

  const { startRecording, mediaBlobUrl, stopRecording, clearBlobUrl, setCurrentRTLanguage, setTargetRTLanguage } =
    useStreamSTT();

  const startRecordingHandler = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mediaStreamRef.current = stream;
        dispatch(analyzeAction.clearRealtimeStt());
        clearBlobUrl();
        dispatch(analyzeAction.clearDiarizedResult());
        dispatch(analyzeAction.setCurrentAudioFilename(''));
        setFilename('Title');
        setIsRecording(true);
        setBlob(undefined);
        setDownloadFileAudio('');
        setDownloadLink('');
        setDownloadTranslationLink('');
        setIsAnalyzed(false);
        setFileIsUpload(false);
        setIsDataRecorded(false);
        setCurrentData([]);
        startRecording();
        setCurrentPlayTime(undefined);
      })
      .catch((error) => {
        console.log(error);
        notifyErr(t('warningMessageMicrophoneAccess'), '9');
      });
  };

  const stopRecordingHandler = () => {
    setIsRecording(false);
    setIsDataRecorded(true);
    stopRecording();
    if (mediaStreamRef.current) {
      stopMicrophone(mediaStreamRef.current);
    }
  };

  const resetRecordingHandler = () => {
    dispatch(analyzeAction.clearRealtimeStt());
    dispatch(analyzeAction.clearDiarizedResult());
    dispatch(analyzeAction.setCurrentAudioFilename(''));
    clearBlobUrl();
    setBlob(undefined);
    setDownloadFileAudio('');
    setFileIsUpload(false);
    setDownloadLink('');
    setDownloadTranslationLink('');
    setIsDataRecorded(false);
    setCurrentData([]);
    setCurrentPlayTime(undefined);
  };

  const fetchBlob = async (
    url: string,
    type?: string,
    name?: string,
    options?: {
      fileIsUpload: boolean;
    },
  ) => {
    if (options?.fileIsUpload) {
      setFileIsUpload(true);
    }

    const response = await fetch(url);
    const responseBlob = await response.blob();

    setBlob(responseBlob);
    setDownloadFileAudio(window.URL.createObjectURL(responseBlob));

    const date = new Date().toISOString().slice(0, 10);
    const newFilename = name ? name : date;

    const file = new File([responseBlob], newFilename, {
      type: type ? type : 'audio/wav',
    });

    setFilename(newFilename);
    dispatch(analyzeAction.setCurrentAudioFilename(newFilename));

    const formData = new FormData();

    formData.append('file', file, name ? name : newFilename);

    setIsPostProcessing(true);
    notifySuc(t('notificationMessageSttProcessing'));

    dispatch(getDiarizedFileAsync({ formData, language: currentLanguage }))
      .then(unwrapResult)
      .then(() => {
        notifySuc(t('successMessagePostProcessing'));
      })
      .catch((error) => {
        console.log(error);
        notifyErr(t('errorMessagePostProcessing'), '10');
      })
      .finally(() => {
        setIsDataRecorded(true);
        setIsAnalyzed(true);
        setIsPostProcessing(false);
      });
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    resetRecordingHandler();

    const { files } = event.target;

    if (!files) {
      setFileUploadError(t('fileUploadError') as string);
      return;
    }

    const blobLink = URL.createObjectURL(files[0]);
    fetchBlob(blobLink, files[0].type, files[0].name, { fileIsUpload: true });

    setFileUploadError('');
  };

  const checkLiveStream = () => {
    let result = true;
    if (currentLanguage === 'ar' || currentLanguage === 'en-US') {
      result = false;
    }
    return result;
  };

  const content = useMemo(() => {
    if (!isRecording && !fileIsUpload && !isDataRecorded) {
      return (
        <StartActionWrapper>
          <ContentTitle>{t('startRecording')}</ContentTitle>
          <RecordActionButton disabled={!checkLiveStream()} onClick={startRecordingHandler}>
            {checkLiveStream() ? <Microphone /> : 'Not available'}
          </RecordActionButton>
          <Text $mb={16}>{t('orUploadFile')}</Text>
          <SelectWrapper>
            <Label htmlFor="language">{t('chooseLanguage')}</Label>

            <Select
              id="language"
              value={currentLanguage}
              onChange={(e) => {
                setCurrentLanguage(e.target.value as SupportedLanguages);
                setCurrentRTLanguage(e.target.value as SupportedLanguages);
              }}>
              <SelectOption value="ko">{t('korean')}</SelectOption>
              <SelectOption value="ar">{t('arabic')}</SelectOption>
              <SelectOption value="en-US">{t('english')}</SelectOption>
              <SelectOption value="ja">{t('japanese')}</SelectOption>
              <SelectOption value="zh">{t('mandarin')}</SelectOption>
              <SelectOption value="ru">{t('russian')}</SelectOption>
            </Select>
          </SelectWrapper>
          <SelectWrapper>
            <Label htmlFor="translate-language">{t('translateLanguage')}</Label>

            <Select
              id="translate-language"
              value={translateLanguage}
              onChange={(e) => {
                setTranslateLanguage(e.target.value as SupportedLanguages);
                setTargetRTLanguage(e.target.value as SupportedLanguages);
              }}>
              <SelectOption value="ko">{t('korean')}</SelectOption>
              <SelectOption value="ar">{t('arabic')}</SelectOption>
              <SelectOption value="en-US">{t('english')}</SelectOption>
              <SelectOption value="ja">{t('japanese')}</SelectOption>
              <SelectOption value="zh">{t('mandarin')}</SelectOption>
              <SelectOption value="ru">{t('russian')}</SelectOption>
            </Select>
          </SelectWrapper>
          <InputButton
            text={t('uploadAudioFile')}
            icon={<UploadIcon />}
            accept=".mp3, .wav, .flac, .mp4"
            onChange={onLoadFile}
            errorMessage={fileUploadError}
          />
        </StartActionWrapper>
      );
    } else if (
      isRecording ||
      (fileIsUpload && isDataRecorded) ||
      currentData.length ||
      diarizedResult?.diarization?.length
    ) {
      return (
        <SttContent
          sttData={diarizedResult?.diarization || []}
          inputRef={titleInputRef}
          realtimeStt={currentData}
          title={filename}
          exportTextLink={downloadLink}
          exportTranslationLink={downloadTranslationLink}
          isRecordingProcess={isRecording || diarizedResultIsLoading || isPostProcessing || !isAnalyzed}
          currentPlayTime={currentPlayTime}
          isPostProcessing={isPostProcessing || diarizedResultIsLoading}
        />
      );
    }
    return (
      <ContentEmpty>
        {diarizedResultIsLoading || isPostProcessing || !isAnalyzed ? <Spinner /> : <div>{t('unrecognizedWords')}</div>}
      </ContentEmpty>
    );
  }, [
    isDataRecorded,
    isRecording,
    fileIsUpload,
    currentData,
    diarizedResult,
    diarizedResultIsLoading,
    isPostProcessing,
    isAnalyzed,
    filename,
    downloadLink,
    downloadTranslationLink,
    titleInputRef,
    currentPlayTime,
    currentLanguage,
    translateLanguage,
  ]);

  useEffect(() => {
    if (realtimeStt && realtimeStt.length > 0) {
      const data = groupBySpeaker(realtimeStt);

      setCurrentData(data);
    }
  }, [realtimeStt]);

  useEffect(() => {
    if (diarizedResult && diarizedResult.diarization.length > 0) {
      const current = diarizedResult.diarization.map((d) => ({
        speaker: d.speakerId,
        words: d.words,
        isBookmarked: d.isBookmarked,
        start: d.start,
      }));
      setCurrentData(current);
      makeTextFile(current, downloadLink, setDownloadLink);

      translateText();
    }
  }, [diarizedResult]);

  useEffect(() => {
    if (mediaBlobUrl) {
      fetchBlob(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (currentAudioFilename) setFilename(currentAudioFilename);
  }, [currentAudioFilename]);

  useEffect(() => {
    return () => resetRecordingHandler();
  }, []);

  const translateText = async () => {
    if (diarizedResult?.diarization && currentLanguage !== translateLanguage) {
      dispatch(
        getTranslationAsync({
          originalText: diarizedResult.diarization.map(
            (d) => `<speaker ${d.speakerId + 1}>: ${d.words.join(' ')} </speaker ${d.speakerId + 1}>`,
          ),
          sourceLanguage: currentLanguage,
          targetLanguage: translateLanguage,
        }),
      )
        .then(unwrapResult)
        .then((data) => {
          const blob = new Blob(['\ufeff', data.translation], { type: 'text/plain;charset=utf-8' });

          if (downloadTranslationLink !== '') window.URL.revokeObjectURL(downloadTranslationLink);

          setDownloadTranslationLink(window.URL.createObjectURL(blob));
        });
    }
  };

  return (
    <Root>
      <Header>
        <div>
          <h1>{t('headerOfSTTTitle')}</h1>
          <h5>{t('headerOfSTTDescription')}</h5>
          <TagWrapper>
            {tags.map((item, i) => {
              return <Tag key={i}>{t(item)}</Tag>;
            })}
          </TagWrapper>
        </div>
      </Header>
      <DiarizedContent>
        <ContentWrapper>{content}</ContentWrapper>
      </DiarizedContent>

      <DetailedPlaybackWrapper>
        <DetailedPlayback
          isDisabledButtons={!isDataRecorded || isPostProcessing || !isAnalyzed}
          blob={blob}
          websufRefCopy={websufRef}
          onResetClick={resetRecordingHandler}
          exportFileLink={downloadFileAudio}
          exportFileName={currentAudioFilename}
          onStopClick={stopRecordingHandler}
          isCanStopped={isRecording}
          setCurrentPlayTime={setCurrentPlayTime}
        />
      </DetailedPlaybackWrapper>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  min-height: 100%;
  max-height: 100%;

  grid-template-areas:
    'header'
    'content'
    'playback';

  @media (max-height: 850px) {
    padding-bottom: 10px;
  }

  @media (max-width: 765px) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 765px) and (orientation: portrait) {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  > div:first-child {
    display: flex;
    flex-flow: column;
  }
  > div:nth-child(2) {
    display: flex;
    margin-right: 28px;
  }
  h1 {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 1.5rem;
    color: #1a2b7c;
    margin: 0 0 16px 0;
  }
  h5 {
    max-width: 760px;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 850px) {
    margin-top: 0;
    flex-flow: column;
    align-items: flex-start;
    > div:first-child {
      h1 {
        margin: 16px 0;
      }
      h5 {
        white-space: inherit;
      }
    }
    > div:nth-child(2) {
      margin-right: 0;
      justify-content: flex-end;
    }
  }
  @media (max-width: 765px) {
    margin-bottom: 8px;
  }
`;

const Tag = styled.div`
  width: fit-content;
  font-family: 'Noto Sans KR';
  background-color: #d9d9d9;
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 700;
  color: #424565;
  margin: 0.25rem 0.5rem 0.25rem 0;

  &:first-child {
    margin-left: 0;
  }
`;

const TagWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
`;

const ContentWrapper = styled.div`
  position: relative;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  border-radius: 12px;
  height: calc(100vh - 412px);
  width: 100%;
  overflow: hidden;
  padding: 8px;
  @media (max-width: 765px) {
    height: 100%;
    margin: 0;
    padding: 24px 8px;
  }
`;

const ContentEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme: { typography } }) => typography.largeText};
`;

const DiarizedContent = styled.div`
  @media (max-width: 1160px) {
    grid-template-columns: minmax(200px, 200px) 1fr minmax(200px, 200px);
  }

  @media (max-width: 765px) {
    display: flex;
    grid-template-columns: 1fr;
    grid-template-areas: '.';
    padding-bottom: 8px;
    min-height: 440px;
  }
`;

const ContentTitle = styled.div`
  ${({ theme: { typography } }) => typography.largeText};
  margin-bottom: 12px;
`;

const RecordActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  min-height: 80px;
  border: 2px solid #6dc4ff;
  border-radius: 50%;
  cursor: pointer;

  @media (max-height: 850px) {
    margin-bottom: 22px;
  }
`;

const Text = styled.div<{ $mb?: number }>`
  ${({ theme: { typography } }) => typography.text};
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : 0)};
`;

const StartActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
  justify-content: space-between;
  margin: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DetailedPlaybackWrapper = styled.div`
  grid-area: playback;
`;

const Label = styled.label`
  color: ${({ theme: { colors } }) => colors.blue};
`;

const SelectWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
`;

const Select = styled.select`
  border-radius: 6px;
  padding: 4px;
`;

const SelectOption = styled.option``;

export default RealtimeSTT;
