import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import * as tranEn from './files/en';
import * as tranKo from './files/ko';

export const languages = ['en', 'ko'];

export type Languages = (typeof languages)[number]; // 'en' | 'ko'

const resources = {
  en: {
    auth: tranEn.authTranslation,
    stt: tranEn.sttTranslation,
    gpt: tranEn.gptTranslation,
    sid: tranEn.sidTranslation,
  },
  ko: {
    auth: tranKo.authTranslation,
    stt: tranKo.sttTranslation,
    gpt: tranKo.gptTranslation,
    sid: tranKo.sidTranslation,
  },
};

export const userLanguage = window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'ko',
  fallbackLng: 'ko',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
