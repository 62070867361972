import { createAsyncThunk } from '@reduxjs/toolkit';
import { SignInDto, SignInResDto, SignUpDto } from 'api/auth.api';
import { ExtraParamsThunkType } from 'store/types';
import { RequestErrors } from 'types';

export const signInAsync = createAsyncThunk<SignInResDto, SignInDto, ExtraParamsThunkType<RequestErrors>>(
  'auth/login',
  async (userData: SignInDto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.AuthApi.signIn(userData);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const signUpAsync = createAsyncThunk<any, SignUpDto, ExtraParamsThunkType<RequestErrors>>(
  'auth/register',
  async (userData: SignUpDto, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.AuthApi.signUp(userData);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const verifyTokenAsync = createAsyncThunk<any, string, ExtraParamsThunkType<RequestErrors>>(
  'auth/verify-token',
  async (token: string, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.AuthApi.verifyToken(token);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requestVerificationTokenAsync = createAsyncThunk<any, string, ExtraParamsThunkType<RequestErrors>>(
  'auth/verification-token',
  async (email: string, { extra: { api }, rejectWithValue }) => {
    try {
      const { data } = await api.AuthApi.requestVerifyToken(email);

      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  },
);
