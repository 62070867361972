import { AxiosResponse } from 'axios';
import { gptHttp } from 'services/http';
import { ResponseUnionType } from 'types';

export interface UploadFilesDto {
  formData: FormData;
}

export interface UploadFilesResponseDto {
  content: string[];
}

export type GetKnowledgeResDto = {
  userId: string;
  status: string;
};

export const ChatApi = {
  uploadFiles: async (data: UploadFilesDto): Promise<AxiosResponse<ResponseUnionType<UploadFilesResponseDto>>> => {
    return gptHttp.post('upload', data.formData, {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
    });
  },
  getKnowledge: async (): Promise<AxiosResponse<GetKnowledgeResDto>> => {
    return gptHttp.get('knowledge');
  },
};
