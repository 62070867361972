import { InputButton, MicrophoneAudioVisualizer, RecordStopButton } from 'components/atoms';
import CircleLoader from 'components/atoms/CircleLoader/CircleLoader';
import { Microphone, UploadIcon } from 'components/atoms/svg';
import { Playback } from 'components/molecules';
import { gaMeasurementId } from 'config';
import { AuthorizedRoutes } from 'constants/routes';
import useMediaRecorder from 'hooks/useMediaRecorder';
import useTimer from 'hooks/useTimer';
import CreatePortal from 'layouts/Modal/CreatePortal';
import Modal from 'layouts/Modal/Modal';
import { ChangeEvent, useEffect, useState } from 'react';
import { ListUl } from 'react-bootstrap-icons';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { notifyErr, notifySuc } from 'utils/notification';

import { SpeakersApi } from '../../api/speakers.api';

ReactGA.initialize(gaMeasurementId);

const NewSpeakerPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['sid']);
  ReactGA.send({ hitType: 'pageview', page: '/sid/new-speaker', title: 'SID Demo' });
  const tags = [
    'least20secondRecording',
    'minimum6secondRequired',
    'noiseIsNoProblem',
    // 'minimumSize20MB',
    'noSpeakerDiarization',
  ];
  const [isVerificationStarted, setIsVerificationStarted] = useState(false);
  const [isRecordingStoped, setIsRecordingStoped] = useState(false);
  const [blob, setBlob] = useState<Blob>();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [filename, setFilename] = useState('');
  const [ticker, setTicker] = useState<number>(0);
  const [isNewSpeakerCreated, setIsNewSpeakerCreated] = useState(false);
  const [isVoiceCreating, setIsVoiceCreating] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);

  const { startRecording, mediaBlobUrl, stopRecording, clearBlobUrl, pauseRecording, resumeRecording } =
    useMediaRecorder();
  const { toggleStopStart, time, reset: resetTimer } = useTimer();
  const second = Number(time.split(':').pop());

  const startRecordingHandler = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        startRecording();
        setIsVerificationStarted(true);
        toggleStopStart();
        clearBlobUrl();
      })
      .catch((error) => {
        console.log(error);
        notifyErr(t('warningMessageMicrophoneAccess'), '7');
      });
  };

  const stopRecordingHandler = () => {
    setVisible(!visible);

    pauseRecording();
    toggleStopStart();
    setIsRecordingStoped(true);
  };

  const fetchBlob = async (
    url: string,
    type: string,
    name?: string,
    options?: {
      fileIsUpload: boolean;
    },
  ) => {
    if (options?.fileIsUpload) {
      setIsVerificationStarted(true);
    }

    const response = await fetch(url);
    const responseBlob = await response.blob();

    setBlob(responseBlob);

    const file = new File([responseBlob], name ? name : filename || '', {
      type: type ? type : 'audio/wav',
    });

    const formData = new FormData();
    formData.append('audio', file, file.name);

    const newFilename = file.name ? file.name : filename;

    SpeakersApi.createNewSpeakerModel(newFilename, formData).then((data) => {
      if (data.data.statusCode === 201) {
        notifySuc(t('successMessageAddSpeaker'));
        setIsNewSpeakerCreated(true);
        setIsVoiceCreating(false);
        setTimeout(() => {
          navigate(AuthorizedRoutes.REAL_SID);
        }, 6000);
      } else {
        notifyErr(t('errorMessageAddSpeaker'), '8');
      }
    });
  };

  const onLoadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files[0]) {
      const blobLink = URL.createObjectURL(files[0]);
      fetchBlob(blobLink, files[0].type, files[0].name, { fileIsUpload: true });
      setFilename(files[0].name);
      setIsFileUploaded(true);
      setIsRecordingStoped(true);
      setIsVoiceCreating(true);
    }
  };

  useEffect(() => {
    if (mediaBlobUrl) fetchBlob(mediaBlobUrl, 'audio/wav');
  }, [mediaBlobUrl]);

  const onModalClose = () => {
    setVisible(false);
    resumeRecording();
    toggleStopStart();
  };
  const onSubmitModal = () => {
    stopRecording();
    resetTimer();
    toggleStopStart();
    setIsRecordingStoped(true);
    setTimeout(() => {
      navigate(AuthorizedRoutes.REAL_SID);
    }, 6000);
  };
  useEffect(() => {
    stisfiedTime();
  }, [time]);
  const stisfiedTime = () => {
    const deg = second * 18;
    const min = Number(time.split(':')[1]);

    if (second <= 20 && min < 1) {
      setTicker(deg);
    } else {
      setTicker(360);
    }
  };

  return (
    <Root>
      <Header>
        <div>
          <h1>{t('headerOfSIDTitle')}</h1>
          <h5>{t('headerOfSIDDescription')}</h5>
          <TagWrapper>
            {tags.map((item, i) => {
              if (navigator.language == 'en' && i < 3) {
                return <Tag key={i}>{t(item)}</Tag>;
              } else if (navigator.language !== 'en') {
                return <Tag key={i}>{t(item)}</Tag>;
              }
            })}
          </TagWrapper>
        </div>
        <ListBtn onClick={() => navigate(AuthorizedRoutes.REAL_SID)}>
          <RefreshChatButtonText>{t('return')}</RefreshChatButtonText>
          <ListUl size={24} color={'#5868f5'} />
        </ListBtn>
      </Header>
      {!isVerificationStarted ? (
        <VerificationBlock>
          <StartActionWrapper>
            <StartContentTitle>{t('startRecording')}</StartContentTitle>
            <RecordActionButton onClick={startRecordingHandler}>
              <Microphone />
            </RecordActionButton>
            <Text $mb={16}>{t('orUploadFile')}</Text>
            <InputButton
              text={t('uploadAudioFile')}
              icon={<UploadIcon />}
              accept=".mp3, .wav, .flac"
              onChange={onLoadFile}
            />
          </StartActionWrapper>
        </VerificationBlock>
      ) : (
        <VerificationBlock>
          <Content>
            <Center>
              {isVoiceCreating && <ReadyText>{t('voiceprintIsCreating')}</ReadyText>}
              {isNewSpeakerCreated && <ReadyText>{t('voiceprintIsReady')}</ReadyText>}
              {!isFileUploaded && <Timer>{time}</Timer>}
              {!isRecordingStoped && (
                <CircleLoader percentage={ticker}>
                  <RecordStopButton stopAction={stopRecordingHandler} />
                </CircleLoader>
              )}
              {blob || isFileUploaded ? <Playback blob={blob} /> : <StyledMicrophoneAudioVisualizer />}
            </Center>
          </Content>
        </VerificationBlock>
      )}
      <CreatePortal>
        {visible ? (
          <Modal onClose={onModalClose} onSubmit={onSubmitModal} setFileName={setFilename} time={second}></Modal>
        ) : null}
      </CreatePortal>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  min-height: 100%;
  max-height: 100%;

  grid-template-areas:
    'header'
    'verification';

  @media (max-width: 765px) {
    padding-bottom: 20px;
  }

  @media (max-height: 850px) {
    padding-bottom: 10px;
  }

  @media screen and (max-width: 765px) and (orientation: portrait) {
    height: 100%;
    overflow-y: scroll;
    /* padding-bottom: 90px; */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Header = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    font-size: 1.5rem;
    color: #1a2b7c;
    margin: 0 0 16px 0;
  }
  h5 {
    max-width: 760px;
    margin: 0;
    font-family: 'Noto Sans KR';
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-line;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 850px) {
    margin-top: 0;
    flex-flow: column;
    align-items: flex-start;
    > div:first-child {
      h1 {
        margin: 16px 0;
      }
      h5 {
        white-space: inherit;
      }
    }
    > div:nth-child(2) {
      margin-right: 0;
      justify-content: flex-end;
    }
  }
  @media (max-width: 765px) {
    margin-bottom: 8px;
  }
`;

const Tag = styled.div`
  width: fit-content;
  font-family: 'Noto Sans KR';
  background-color: #d9d9d9;
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 700;
  color: #424565;
  margin: 0.25rem 0.5rem 0.25rem 0;

  &:first-child {
    margin-left: 0;
  }
`;

const TagWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
`;

const VerificationBlock = styled.div`
  grid-area: verification;
  display: flex;
  position: relative;
  border-radius: 12px;
  height: calc(100vh - 412px);
  width: 100%;
  overflow: hidden;
  background: ${({ theme: { colors } }) => colors.lightBckg};
  padding: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ListBtn = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.blue};

  cursor: pointer;
`;

const StartActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: -75px;
`;

const Text = styled.div<{ $mb?: number }>`
  ${({ theme: { typography } }) => typography.text};
  margin-bottom: ${({ $mb }) => ($mb ? $mb + 'px' : 0)};
`;

const Timer = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  width: 102px;
  color: ${({ theme: { colors } }) => colors.accent};
`;

const RecordActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 2px solid #6dc4ff;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 90px;

  @media (max-height: 850px) {
    margin-bottom: 22px;
  }
`;

const StartContentTitle = styled.div`
  ${({ theme: { typography } }) => typography.largeText};
  margin-bottom: 12px;
`;

const RefreshChatButtonText = styled.div`
  ${({ theme: { typography } }) => typography.text};
  color: ${({ theme: { colors } }) => colors.blue};
  margin-right: 8px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const StyledMicrophoneAudioVisualizer = styled(MicrophoneAudioVisualizer)``;

const ReadyText = styled.div`
  ${({ theme: { typography } }) => typography.largeText}
`;

export default NewSpeakerPage;
