import Input, { InputProps } from 'components/atoms/Input/Input';
import { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputField = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const { name, control, rules, ...rest } = props;
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  return (
    <div>
      <StyledInput
        value={field.value || ''}
        onChange={handleChange}
        hasError={!!fieldState.error}
        $hasError={!!fieldState.error}
        {...rest}
        name={name}
      />
      <Error>{fieldState.error && fieldState.error.message}</Error>
    </div>
  );
};

const StyledInput = styled(Input)<{ $hasError: boolean }>`
  margin-bottom: ${({ $hasError }) => ($hasError ? '4px' : 0)};
`;

const Error = styled.div`
  color: ${({ theme: { colors } }) => colors.red};
`;

export default InputField;
